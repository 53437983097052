import React from "react";
import styled from "styled-components";
import Logo from "../assets/images/Logo.png";

const TicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  min-width: 500px;

  @media print {
    width: 500px;
    height: fit-content;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px dashed #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const LogoImage = styled.img`
  max-width: 50px;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin: 0;
  text-align: center;
  flex-grow: 1;
  color: #333;
`;

const TicketSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  font-size: 0.9em;
  justify-content: space-between;
`;

const InfoItem = styled.p`
  display: flex;
  flex-direction: row;
  margin: 0;
  &:nth-child(odd) {
    padding-right: 10px;
  }
`;

const Strong = styled.span`
  font-weight: bold;
  color: #444;
  margin-right: 5px;
`;

const Footer = styled.div`
  border-top: 2px dashed #ddd;
  padding-top: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.8em;
  color: #777;
`;

const formatDate = (fecha) => {
  const [year, month, day] = fecha.split("-");
  const fechaObjeto = new Date(year, month - 1, day);
  const opcionesMes = { month: "long" };

  let nombreDelMes = fechaObjeto.toLocaleDateString("es-ES", opcionesMes);

  nombreDelMes = nombreDelMes.charAt(0).toUpperCase() + nombreDelMes.slice(1);

  return `${day} de ${nombreDelMes} de ${year}`;
};

const ImageTicket = React.forwardRef((props, ref) => (
  <TicketWrapper ref={ref}>
    <Header>
      <LogoImage src={Logo} alt="CompanyLogo" />
      <Title>Viajes del Sur</Title>
    </Header>
    <TicketSection>
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <InfoItem>
          <Strong>Pasajero: </Strong> {props.name} {props.last_names}
        </InfoItem>
        <InfoItem>
          <Strong>Origen: </Strong> {props.origin}
        </InfoItem>
        <InfoItem>
          <Strong>Destino: </Strong> {props.destiny}
        </InfoItem>
        <InfoItem>
          <Strong>Teléfono: </Strong> {props.phone_number}
        </InfoItem>
        <InfoItem>
          <Strong>Fecha de viaje: </Strong>

          <span>{formatDate(props.trip_date)}</span>
        </InfoItem>
        <InfoItem>
          <Strong>Hora de viaje: </Strong> {props.time.slice(0, 5)} Hrs
        </InfoItem>
        <InfoItem>
          <Strong>Oficina de registro: </Strong> {props.office_name}
        </InfoItem>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <InfoItem>
          <Strong>Lugar de abordaje: </Strong>
          {props.office_departure ? (
            <span>{props.office_departure}</span>
          ) : (
            <span>{props.office_name}</span>
          )}
        </InfoItem>
        <InfoItem>
          <Strong>Vendedor: </Strong> {props.user_name}
        </InfoItem>
        <InfoItem>
          <Strong>Cantidad abonada: </Strong> ${props.amount_paid}
        </InfoItem>
        <InfoItem>
          <Strong>Precio total: </Strong> ${props.total_amount}
        </InfoItem>
        <InfoItem>
          <Strong>Debe: </Strong> ${props.amount_debt}
        </InfoItem>
        <InfoItem>
          <Strong>Numero de asientos: </Strong>
          {props.seats.map((seat) => seat).join(", ")}
        </InfoItem>
      </div>
    </TicketSection>
    <Footer>
      Tiene derecho a 25 kilos de equipaje (no incluye electrónicos), si usted
      trae exceso de equipaque se le cobrará. El ticket de abordaje será válido
      unicamente para el destino, fecha y hora de salida, no hay devoluciones.
      Favor de estar 30 minutos antes de la hora de salida.
      <p>Informes o dudas: 656178309 / 6561858132 / 6564059239</p>
    </Footer>
  </TicketWrapper>
));

export default ImageTicket;
