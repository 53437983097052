import React, { useState, useEffect } from "react";
import LoadingScreen from "../components/loadingScreen";
import CloseIcon from "@mui/icons-material/Close";
import { SelectInputAsync, SelectInput } from "../components/inputs";
import PaidIcon from "@mui/icons-material/Paid";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../components/inputs";
import InputAdornment from "@mui/material/InputAdornment";
import { CustomIconButton } from "../components/buttons";
import { toast, Bounce } from "react-toastify";

const PayTicketPopUp = ({ ticketData, closeMethod }) => {
  const [paymentType, setPaymentType] = useState(null);
  const navigate = useNavigate();
  const [officeOptions, setOfficeOptions] = useState(null);
  const [office, setOffice] = useState(null);
  const [payment, setPayment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [folio, setFolio] = useState("");

  const handleClose = () => {
    document.body.classList.remove("no-scroll");
    closeMethod(false);
  };

  const handleChangePayment = (e) => {
    setPayment(e.target.value);
  };

  const handlePayButtonUpdate = () => {
    setIsLoading(true);
    if (parseFloat(payment) > parseFloat(ticketData.amount_debt)) {
      setIsLoading(false);
      toast.error("La cantidad a abonar debe ser igual o menor que la deuda", {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else if (parseFloat(payment) === 0) {
      setIsLoading(false);
      toast.error("La cantidad a abonar debe ser mayor que 0", {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      axiosInstance
        .patch(`/tickets/${ticketData.id}/`, {
          amount_paid: parseFloat(ticketData.amount_paid) + parseFloat(payment),
          amount_debt: parseFloat(ticketData.amount_debt) - parseFloat(payment),
        })
        .then((response) => {
          const payment_data = {
            vendor_id: localStorage.getItem("user_id"),
            payment_type: paymentType.value,
            amount: parseFloat(payment),
            payment_date: new Date(),
            ticket_id: response.data["id"],
            office_id: office.value,
          };

          if (folio !== "") {
            payment_data["folio"] = folio;
          }

          axiosInstance
            .post("/payments/", payment_data)
            .then((response) => {
              setIsLoading(false);
              Swal.fire({
                title: "Abono aplicado",
                text: "Abono aplicado al boleto selecionado",
                icon: "success",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
                willClose: () => {
                  window.location.reload();
                },
              })
            })
            .catch((error) => {
              if (error.code === "TOKEN_EXP") {
                Swal.fire({
                  title: "Error",
                  text: "Sesión cerrada por seguridad, inicie nuevamente",
                  icon: "error",
                  iconColor: "#1ccda0",
                  confirmButtonColor: "#0da290",
                });
                navigate("/");
                localStorage.clear();
              } else {
                Swal.fire({
                  title: "Error",
                  text: error.message,
                  icon: "error",
                  iconColor: "#1ccda0",
                  confirmButtonColor: "#0da290",
                });
              }
            });
        })
        .catch((error) => {
          if (error.code === "TOKEN_EXP") {
            Swal.fire({
              title: "Error",
              text: "Sesión cerrada por seguridad, inicie nuevamente",
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
            navigate("/");
            localStorage.clear();
          } else {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const loadPaymentOptions = () => {
    return axiosInstance
      .get(`/payment/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((row) => ({
          label: row.name,
          value: row.id,
        }));
        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    axiosInstance
      .get(`/offices/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((office) => ({
          label: office.name,
          value: office.id,
        }));
        setOfficeOptions(options);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  }, [navigate]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className="popup">
      <div className="popup-inner">
        {officeOptions && (
          <div
            style={{
              width: "100%",
              flexDirection: "column",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1 style={{ textAlign: "center" }}>Registrar pago</h1>
            <div
              style={{
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  maxWidth: "230px",
                  width: "100%",
                }}
              >
                <label>Tipo de pago</label>
                <SelectInputAsync
                  placeholder={"Seleccione uno"}
                  val={paymentType}
                  onchange={(e) => setPaymentType(e)}
                  maxWidth={"230px"}
                  maxHeight={"56px"}
                  options={loadPaymentOptions}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  maxWidth: "230px",
                  width: "100%",
                }}
              >
                <label>Oficina de registro</label>
                <SelectInput
                  placeholder={"Seleccione uno"}
                  val={office}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                  options={officeOptions}
                  onChange={(e) => setOffice(e)}
                />
              </div>
              <TextInput
                label={"Cantidad a Abonar"}
                onChange={handleChangePayment}
                maxWidth={"230px"}
                maxHeight={"56px"}
                disabled={paymentType === null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextInput
                label={"Clave de rastreo"}
                onChange={(e) => setFolio(e.target.value)}
                value={folio}
                maxWidth="230px"
                maxHeight="56px"
                disabled={
                  paymentType === null ||
                  paymentType.label === "Dinero en efectivo"
                }
              />
            </div>
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              onClick={handlePayButtonUpdate}
              text={"Registrar pago"}
              disabled={payment === ""}
              icon={<PaidIcon />}
            />
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              text={"Cerrar"}
              onClick={handleClose}
              icon={<CloseIcon />}
              bColor="#A52A2A"
              bColorHover="#761e1e"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PayTicketPopUp;
