import React, { useState, useRef } from "react";
import "./customerSearcher.css";
import TableComponent from "../components/tableComponent";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SearchButton } from "../components/buttons";
import axiosInstance from "../interceptors/axiosInstance";
import TicketViewer from "../components/ticketViewer";
import { toast, Bounce } from "react-toastify";
import {
  TableSkeletonComponent,
  SkeletonFormComponent,
} from "../components/skeletonComponents";
import { TextInput, DateInput } from "../components/inputs";
import { Slide, useMediaQuery } from "@mui/material";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const Cards = styled(Card)`
  background: ${(props) =>
    props.isClicked ? "#a1e0f3 !important" : "#1990b7"};
  color: black;
  border: none;
  margin-bottom: 10px;
  flex: 0 0 auto;
  min-width: 200px;
  padding: 10px;
  border-radius: 20px;
  flex-wrap: wrap;

  &:hover {
    background-color: #a1e0f3 !important;
    cursor: pointer;
  }
`;

const CustomerSearcher = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [clickedRowId, setClickedRowId] = useState(null);
  const buttonRef = useRef(null);
  const [loadingData, setIsLoadingData] = useState(false);
  const [loadingTicketData, setIsLoadingTicketData] = useState(false);
  const [customersData, setCustomersData] = useState(null);
  const [ticketInformation, setTicketInformation] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    last_name: "",
    trip_date: null,
  });
  const [checked, setChecked] = React.useState(false);

  const handleInputChange = (field) => {
    return (e) => {
      setUser((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };
  };

  const handleDateChange = (field) => {
    return (e) => {
      setUser((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleSearchCustomer = () => {
    if (!Object.values(user).every((value) => value !== "" && value !== null)) {
      toast.error("Debes rellenar todos los campos de búsqueda", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      setIsLoadingData(true);
      axiosInstance
        .get("/tickets/", {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            name: user.name,
            last_names: user.last_name,
            origin: user.origin,
            destiny: user.destiny,
            trip_date: user.trip_date.format("YYYY-MM-DD"),
          },
        })
        .then((response) => {
          if (response.status === 204) {
            Swal.fire({
              title: "Advertencia",
              text: "No se encontraron pasajeros con la información proporcionada.",
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
            setIsLoadingData(false);
          } else {
            setChecked(true);
            setIsLoadingData(false);
            setCustomersData(response);
          }
        })
        .catch((error) => {
          if (error.code === "TOKEN_EXP") {
            Swal.fire({
              title: "Error",
              text: "Sesión cerrada por seguridad, inicie nuevamente",
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
            navigate("/");
            localStorage.clear();
          } else {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
          }
          setIsLoadingData(setIsLoadingData);
        });
    }
  };

  const handleCustomerClick = (row) => {
    setClickedRowId(row.id);
    setIsLoadingTicketData(true);
    axiosInstance
      .get(`/tickets/${row.id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTicketInformation(response);
        setIsLoadingTicketData(false);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
        setIsLoadingTicketData(true);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      buttonRef.current.click();
    }
  };

  return (
    <div className="customerSearchContainer">
      <div className="customerSearcherBar">
        <TextInput
          label={"Nombre"}
          onChange={handleInputChange("name")}
          value={user.name}
          onKeyDown={handleKeyDown}
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
        <TextInput
          label={"Apellidos"}
          onChange={handleInputChange("last_name")}
          value={user.last_name}
          onKeyDown={handleKeyDown}
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
        <DateInput
          val={user.trip_date}
          onchange={handleDateChange("trip_date")}
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
        <SearchButton
          ref={buttonRef}
          maxWidth={"150px"}
          maxHeight={"56px"}
          onClick={handleSearchCustomer}
          minWidth={"115px"}
        />
      </div>
      <div className="customerSearcherBarMobile">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <TextInput
            label={"Nombre"}
            onChange={handleInputChange("name")}
            value={user.name}
            onKeyDown={handleKeyDown}
            maxWidth={"250px"}
            maxHeight={"56px"}
            minWidth={"199px"}
          />
          <TextInput
            label={"Apellidos"}
            onChange={handleInputChange("last_name")}
            value={user.last_name}
            onKeyDown={handleKeyDown}
            maxWidth={"250px"}
            maxHeight={"56px"}
            minWidth={"199px"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <DateInput
            val={user.trip_date}
            onchange={handleDateChange("trip_date")}
            maxWidth={"250px"}
            maxHeight={"56px"}
            minWidth={"199px"}
          />
          <SearchButton
            ref={buttonRef}
            maxWidth={"150px"}
            maxHeight={"56px"}
            onClick={handleSearchCustomer}
            minWidth={"115px"}
          />
        </div>
      </div>
      {loadingData ? (
        <TableSkeletonComponent nRows={4} />
      ) : isMobile ? (
        customersData && (
          <Slide direction="up" in={checked}>
            <div className="customerSearcherContentMobile">
              <h2 style={{ marginTop: "0px" }}>Pasajeros encontrados</h2>
              <div className="customersMobileViewer">
                {customersData.data.map((row) => (
                  <Cards
                    key={row.id}
                    isClicked={clickedRowId === row.id}
                    onClick={() => handleCustomerClick(row)}
                  >
                    <Cards.Body>
                      <Cards.Text>
                        <span style={{ fontStyle: "italic" }}>Nombre: </span>
                        {row.name}
                        <br />
                        <span style={{ fontStyle: "italic" }}>
                          Apellido(s):{" "}
                        </span>
                        {row.last_names}
                        <br />
                        <span style={{ fontStyle: "italic" }}>
                          Asiento(s):{" "}
                        </span>
                        {row.seats.join(", ")}
                      </Cards.Text>
                    </Cards.Body>
                  </Cards>
                ))}
              </div>
            </div>
          </Slide>
        )
      ) : (
        customersData && (
          <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
            <div className="customerSearcherContentContainer">
              <h1 style={{ marginTop: "0px" }}>Pasajeros encontrados</h1>
              <TableComponent
                dataInfo={customersData}
                headers={[
                  "Nombre",
                  "Apellidos",
                  "Origen",
                  "Destino",
                  "Fecha de Viaje",
                ]}
                cellName={[
                  "name",
                  "last_names",
                  "origin",
                  "destiny",
                  "trip_date",
                ]}
                clickHandle={handleCustomerClick}
              />
            </div>
          </Slide>
        )
      )}

      {loadingTicketData ? (
        <SkeletonFormComponent />
      ) : (
        ticketInformation && <TicketViewer data={ticketInformation.data} />
      )}
    </div>
  );
};

export default CustomerSearcher;
