import React, { useEffect, useState } from "react";
import { CardSkeletonComponent } from "../components/skeletonComponents";
import { CardTemplate } from "../components/cardComponent";
import { GetAllBusses } from "../api/apiFunctions";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { CustomIconButton } from "../components/buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import TableComponent from "../components/tableComponent";
import { useMediaQuery } from "@mui/material";

const Cards = styled(Card)`
  background: ${(props) =>
    props.isClicked ? "#a1e0f3 !important" : "#1990b7"};
  text-align: start;
  color: black;
  margin-bottom: 10px;
  flex: 0 0 auto;
  padding: 0px 15px;
  border-radius: 15px;

  &:hover {
    background-color: #a1e0f3 !important;
    cursor: pointer;
  }
`;

function DeleteTickets() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [busId, setBusId] = useState(null);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [ticketsInformation, setTicketsInformation] = useState(null);
  const [clickedRowId, setClickedRowId] = useState(null);
  const handleRowClick = (row) => {
    setClickedRowId(row.id);
  };
  const isMobile = useMediaQuery("(max-width:767px)");

  const handleBusClick = (row) => {
    setIsLoadingTickets(true);
    setBusId(row.id);
    axiosInstance
      .get(`/tickets/`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          bus: row.id,
        },
      })
      .then((response) => {
        setIsLoadingTickets(false);
        if (response.status === 204) {
          Swal.fire({
            title: "Advertencia",
            text: "No se encontraron boletos del autobus seleccionado.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        } else {
          setTicketsInformation(response);
        }
      })
      .catch((error) => {
        setIsLoadingTickets(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  const handleDeleteTicketButton = () => {
    Swal.fire({
      title: "Atención",
      text: "¿Esta seguro de eliminar el boleto seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/tickets/${clickedRowId}/`)
          .then((response) => {
            Swal.fire({
              title: "Proceso completado",
              text: `El boleto se ha eliminado exitosamente.`,
              icon: "success",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
              willClose: () => {
                window.location.reload();
              },
            });
          })
          .catch((error) => {
            if (error.code === "TOKEN_EXP") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              navigate("/");
              localStorage.clear();
            } else {
              Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          many: false,
        },
      }
    );
  }, [navigate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label>1- Seleccione una salida para ver los boletos asociados</label>
      <div className="editDepartureAvailableDepartures">
        {isLoading ? (
          <CardSkeletonComponent nRows={2} />
        ) : data ? (
          isMobile ? (
            <CardTemplate dataInfo={data} clickHandle={handleBusClick} />
          ) : (
            <TableComponent
              dataInfo={data}
              headers={["Autobus", "Fecha", "Ruta", "Hora"]}
              cellName={["bus_name", "date", "route_name", "time"]}
              clickHandle={handleBusClick}
            />
          )
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: busId ? "flex" : "none",
          flexDirection: "column",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <label>2- Seleccione un boleto para eliminarlo</label>
        <div className="editDepartureAvailableDepartures">
          {isLoadingTickets ? (
            <CardSkeletonComponent nRows={2} />
          ) : (
            ticketsInformation &&
            ticketsInformation.data
              .sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              })
              .map((row) => (
                <Cards
                  key={row.id}
                  isClicked={clickedRowId === row.id}
                  onClick={() => handleRowClick(row)}
                >
                  <Cards.Body>
                    <Cards.Text>
                      <span>Nombre: {row.name}</span>
                      <br />
                      <span>Apellido(s): {row.last_names}</span>
                      <br />
                      <span>Asiento(s): {row.seats.join(", ")}</span>
                      <br />
                    </Cards.Text>
                  </Cards.Body>
                </Cards>
              ))
          )}
        </div>
        <CustomIconButton
          maxWidth={"230px"}
          maxHeight={"56px"}
          text={"Borrar boleto"}
          disabled={!clickedRowId}
          icon={<DeleteIcon />}
          onClick={handleDeleteTicketButton}
        />
      </div>
    </div>
  );
}

export default DeleteTickets;
