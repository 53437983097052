import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./administrationPanels.css";
import CreateDeparture from "./createDeparture";
import EditDeparture from "./editDeparture";
import DeleteDeparture from "./deleteDeparture";

const DepartureAdministration = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  return (
    <div className="mainContainer">
      <h3
        onClick={() => setShowCreate(!showCreate)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showCreate ? "0px" : "20px",
        }}
      >
        Crear Salida {showCreate ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showCreate}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <CreateDeparture />
        </div>
      </CSSTransition>

      <h3
        onClick={() => setShowEdit(!showEdit)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showEdit ? "0px" : "20px",
        }}
      >
        Editar Salida {showEdit ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showEdit}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <EditDeparture />
        </div>
      </CSSTransition>

      <h3
        onClick={() => setShowDelete(!showDelete)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showDelete ? "0px" : "20px",
        }}
      >
        Eliminar Salida {showDelete ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showDelete}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <DeleteDeparture />
        </div>
      </CSSTransition>
    </div>
  );
};

export default DepartureAdministration;
