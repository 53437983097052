import React, { useEffect, useState, useRef } from "react";
import { GetAllBusses } from "../api/apiFunctions";
import { useNavigate } from "react-router-dom";
import { TableSkeletonComponent } from "../components/skeletonComponents";
import TableComponent from "../components/tableComponent";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useReactToPrint } from "react-to-print";
import "./departureReport.css";
import { CustomIconButton } from "../components/buttons";
import PrintIcon from "@mui/icons-material/Print";

const CustomTable = styled(Table)`
  table-layout: fixed;
  background-color: white;
`;

const CustomCell = styled(TableCell)`
  font-family: "Poppins", sans-serif;
  padding: 0px;
  border: black solid 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  @media print {
    font-size: 12px;
    border: black solid 1.5px;
  }
`;

const CustomHead = styled(TableHead)`
  .MuiTableCell-root {
    background-color: black;
    color: white;
    @media print {
      background-color: white;
      color: black;
    }
  }
`;

const ScrollableContainer = styled("div")`
  overflow-x: auto;
  max-width: 100%;
`;

const DepartureReport = () => {
  let currentDate = new Date();
  const formattedDate = new Intl.DateTimeFormat("es-ES", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(currentDate);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [seatsInformation, setSeatsInformation] = useState(null);
  const [data, setData] = useState(null);
  const tableRef = useRef();
  const tableRefBus = useRef();

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          many: true,
        },
      }
    );
  }, [navigate]);

  const handleBusClick = (row) => {
    setIsLoadingReport(true);
    axiosInstance
      .get(`/bus/${row.id}/get_available_seats/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoadingReport(false);
        if (response.status === 204) {
          Swal.fire({
            title: "Advertencia",
            text: "No se encontraron pasajeros con la información proporcionada.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        } else {
          setSeatsInformation(response);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrintBus = useReactToPrint({
    content: () => tableRefBus.current,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {isLoading ? (
        <TableSkeletonComponent nRows={6} />
      ) : (
        data && (
          <TableComponent
            dataInfo={data}
            headers={["Autobus", "Fecha", "Ruta", "Hora"]}
            cellName={["bus_name", "date", "route_name", "time"]}
            clickHandle={handleBusClick}
          />
        )
      )}
      {isLoadingReport ? (
        <TableSkeletonComponent nRows={6} />
      ) : (
        seatsInformation && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginBottom: "10px",
                gap: "10px",
              }}
            >
              <CustomIconButton
                maxWidth={"230px"}
                maxHeight={"56px"}
                onClick={() => handlePrint()}
                text={"Imprimir reporte"}
                icon={<PrintIcon />}
              />
              <CustomIconButton
                maxWidth={"230px"}
                maxHeight={"56px"}
                onClick={() => handlePrintBus()}
                text={"Imprimir Reporte de Autobus"}
                icon={<PrintIcon />}
              />
            </div>
            <div className="departureReportContainer">
              <ScrollableContainer>
                <CustomTable>
                  <CustomHead>
                    <TableRow>
                      <CustomCell width={"4%"} align="center">
                        N°
                      </CustomCell>
                      <CustomCell width={"10%"} align="center">
                        Sucursal de registro
                      </CustomCell>
                      <CustomCell width={"8%"} align="center">
                        P. de abordaje
                      </CustomCell>
                      <CustomCell width={"8%"} align="center">
                        Vendedor
                      </CustomCell>
                      <CustomCell width={"8%"} align="center">
                        V. Externo
                      </CustomCell>
                      <CustomCell align="center">Nombre</CustomCell>
                      <CustomCell align="center">Apellidos</CustomCell>
                      <CustomCell width={"8%"} align="center">
                        Telefono
                      </CustomCell>
                      <CustomCell align="center">Origen</CustomCell>
                      <CustomCell align="center">Destino</CustomCell>
                    </TableRow>
                  </CustomHead>
                  <TableBody>
                    {seatsInformation.data.map((row, index) => {
                      const seatLabel =
                        index % 4 === 0 || index % 4 === 3
                          ? `${row.seat_number}V`
                          : `${row.seat_number}P`;

                      return (
                        <TableRow
                          key={row.id}
                          style={
                            row.ticket
                              ? row.ticket.is_ticket_paid === false
                                ? { backgroundColor: "#FFFF00" }
                                : {}
                              : {}
                          }
                        >
                          <CustomCell align="center">{seatLabel}</CustomCell>
                          {row.ticket ? (
                            <>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.office_name}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.office_departure}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.user_name}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.external_seller_name}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.name}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.last_names}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.phone_number}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.origin}
                              </CustomCell>
                              <CustomCell sx={{ paddingLeft: "5px" }}>
                                {row.ticket.destiny}
                              </CustomCell>
                            </>
                          ) : (
                            <>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                              <CustomCell align="center"></CustomCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </CustomTable>
              </ScrollableContainer>
            </div>
            <div className="reportToPrintContainer" ref={tableRef}>
              <p style={{ margin: 0 }}>VIAJES DEL SUR</p>
              <CustomTable>
                <CustomHead>
                  <TableRow>
                    <CustomCell width={"4%"} align="center">
                      N°
                    </CustomCell>
                    <CustomCell align="center">P. de abordaje</CustomCell>
                    <CustomCell align="center">V. Externo</CustomCell>
                    <CustomCell align="center">Nombre</CustomCell>
                    <CustomCell align="center">Apellidos</CustomCell>
                    <CustomCell align="center">Telefono</CustomCell>
                    <CustomCell align="center">Origen</CustomCell>
                    <CustomCell align="center">Destino</CustomCell>
                  </TableRow>
                </CustomHead>
                <TableBody>
                  {seatsInformation.data.map((row, index) => {
                    const seatLabel =
                      index % 4 === 0 || index % 4 === 3
                        ? `${row.seat_number}V`
                        : `${row.seat_number}P`;

                    return (
                      <TableRow
                        key={row.id}
                        style={
                          row.ticket
                            ? row.ticket.is_ticket_paid === false
                              ? { backgroundColor: "#FFFF00" }
                              : {}
                            : {}
                        }
                      >
                        <CustomCell align="center">{seatLabel}</CustomCell>
                        {row.ticket ? (
                          <>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.office_departure}
                            </CustomCell>

                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.external_seller_name}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.name}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.last_names}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.phone_number}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.origin}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.destiny}
                            </CustomCell>
                          </>
                        ) : (
                          <>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </CustomTable>
            </div>
            <div className="reportForBusToPrintContainer" ref={tableRefBus}>
              <label>Fecha: {formattedDate}</label>
              <CustomTable>
                <CustomHead>
                  <TableRow>
                    <CustomCell width={"4%"} align="center">
                      N°
                    </CustomCell>
                    <CustomCell align="center">P. de abordaje</CustomCell>
                    <CustomCell align="center">Nombre</CustomCell>
                    <CustomCell align="center">Apellidos</CustomCell>
                    <CustomCell align="center">Telefono</CustomCell>
                    <CustomCell align="center">Origen</CustomCell>
                    <CustomCell align="center">Destino</CustomCell>
                  </TableRow>
                </CustomHead>
                <TableBody>
                  {seatsInformation.data.map((row, index) => {
                    const seatLabel =
                      index % 4 === 0 || index % 4 === 3
                        ? `${row.seat_number}V`
                        : `${row.seat_number}P`;

                    return (
                      <TableRow
                        key={row.id}
                        style={
                          row.ticket
                            ? row.ticket.is_ticket_paid === false
                              ? { backgroundColor: "#FFFF00" }
                              : {}
                            : {}
                        }
                      >
                        <CustomCell align="center">{seatLabel}</CustomCell>
                        {row.ticket ? (
                          <>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.office_departure}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.name}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.last_names}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.phone_number}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.origin}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              {row.ticket.destiny}
                            </CustomCell>
                          </>
                        ) : (
                          <>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                            <CustomCell align="center"></CustomCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </CustomTable>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DepartureReport;
