import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CreateButton } from "../components/buttons";
import Swal from "sweetalert2";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import LoadingScreen from "../components/loadingScreen";
import axiosInstance from "../interceptors/axiosInstance";
import "./createDeparture.css";
import { useNavigate } from "react-router-dom";
import { TextInput, SelectInputAsync, DateInput } from "../components/inputs";

const CreateDeparture = () => {
  const initialState = {
    busName: "",
    tripDate: null,
    seatNumber: 52,
    route: "",
    tripTime: null,
  };
  const [busInfo, setBusInfo] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [routesData, setRoutesData] = useState({});

  const handleInputChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };
  };

  const handleInputTimeChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleSelectChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newErrors = validateInputs();
    if (newErrors.length > 0) {
      Swal.fire({
        title: "Error",
        text: newErrors,
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
      setIsLoading(false);
    } else {
      axiosInstance
        .post(
          "/bus/",
          {
            bus_name: busInfo.busName,
            date: busInfo.tripDate.format("DD-MM-YYYY"),
            seats: busInfo.seatNumber,
            assigned_route: routesData[busInfo.route.value],
            time: busInfo.tripTime.format("HH:mm"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setBusInfo(initialState);
          Swal.fire({
            title: "Autobus creado",
            text: `Autobús "${response.data.bus_name}" creado con éxito. Fecha: ${response.data.date}, Hora: ${response.data.time}, Asientos: ${response.data.seats}, Ruta: ${response.data.route}`,
            icon: "success",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.code === "TOKEN_EXP") {
            Swal.fire({
              title: "Error",
              text: "Sesión cerrada por seguridad, inicie nuevamente",
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
            localStorage.clear();
            navigate("/");
          } else {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
          }
        });
    }
  };

  const validateInputs = () => {
    let newErrors = [];
    if (!busInfo.busName) newErrors.push("Nombre del autobus");
    if (!busInfo.route) newErrors.push("Ruta");
    if (!busInfo.seatNumber) newErrors.push("Numero de asientos");
    if (!busInfo.tripDate) newErrors.push("Fecha");
    if (!busInfo.tripTime) newErrors.push("Hora");
    if (newErrors.length > 0) {
      let title = "Campos faltantes: ";
      newErrors = title + newErrors.join(", ");
    }
    return newErrors;
  };

  const loadRoutesOptions = () => {
    return axiosInstance
      .get(`/routes/`)
      .then((response) => {
        const options = response.data.map((route) => ({
          label: route.name,
          value: route.value,
        }));
        const routesIds = {};

        response.data.forEach((route) => {
          routesIds[route.value] = route.id;
        });
        setRoutesData(routesIds);

        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <form className="departureForm">
      <TextInput
        label={"Nombre del autobus"}
        onChange={handleInputChange("busName")}
        value={busInfo.busName}
        maxWidth={"250px"}
        maxHeight={"56px"}
        minWidth={"199px"}
      />
      <TextInput
        label={"Numero de asientos"}
        onChange={handleInputChange("seatNumber")}
        value={busInfo.seatNumber}
        maxWidth={"250px"}
        maxHeight={"56px"}
        minWidth={"199px"}
      />
      <DateInput
        val={busInfo.tripDate}
        onchange={handleInputTimeChange("tripDate")}
        maxWidth={"250px"}
        maxHeight={"56px"}
        minWidth={"199px"}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label="Hora de salida"
          className="timePicker"
          ampm={false}
          id="tripTime"
          value={busInfo.tripTime}
          onChange={handleInputTimeChange("tripTime")}
          sx={{ backgroundColor: "white" }}
        />
      </LocalizationProvider>
      <SelectInputAsync
        placeholder={"Ruta del autobus"}
        val={busInfo.route}
        onchange={handleSelectChange("route")}
        options={loadRoutesOptions}
        maxWidth={"250px"}
        maxHeight={"56px"}
        minWidth={"199px"}
      />
      <CreateButton
        maxWidth={"250px"}
        maxHeight={"56px"}
        text={"Crear"}
        onClick={handleSubmit}
      />
    </form>
  );
};

export default CreateDeparture;
