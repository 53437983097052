import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material";
import { toast, Bounce } from "react-toastify";
import { TextInput } from "./inputs";
import { CreateButton, CustomIconButton } from "./buttons";
import Slide from "@mui/material/Slide";
import PrintIcon from "@mui/icons-material/Print";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PrintTicketPopUp from "../windows/printTicketPopUp";
import PayTicketPopUp from "../windows/payTicketPopUp";

const TicketsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-display: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(25, 118, 210, 0.08);
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.3);
  align-items: center;
`;

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-input": {
            "&.Mui-disabled": {
              "-webkit-text-fill-color": "black",
            },
          },
          "&.Mui-disabled": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "black",
          },
        },
      },
    },
  },
});

const TicketViewer = ({ data }) => {
  const amountPaid = useRef(null);
  const slideRef = useRef(null);
  const [checked, setChecked] = React.useState(false);
  const [displayPaymentWindow, setDisplayPaymentWindow] = useState(false);
  const ticketTime = dayjs(data.time, "HH:mm");
  const [openTicketPrint, setOpenTicketPrint] = useState(false);

  const handlePayButton = () => {
    if (parseFloat(data.amount_debt) === 0) {
      toast.warn("El boleto ya está liquidado", {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } else {
      setDisplayPaymentWindow(true);
    }
  };

  useEffect(() => {
    if (checked && slideRef.current) {
      slideRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [checked]);

  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {displayPaymentWindow && (
        <PayTicketPopUp
          ticketData={data}
          closeMethod={() => setDisplayPaymentWindow()}
        />
      )}
      {openTicketPrint && (
        <PrintTicketPopUp
          ticketData={data}
          closeMethod={() => setOpenTicketPrint()}
        />
      )}
      <Slide direction="up" in={checked}>
        <Container ref={slideRef}>
          <h2 style={{ marginTop: "0px" }}>Informacion del boleto</h2>
          <TicketsContainer>
            <TextInput
              label={"Nombre"}
              value={data.name}
              disabled
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              value={data.last_names}
              label={"Apellido"}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Origen"}
              value={data.origin}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Destino"}
              value={data.destiny}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Fecha de viaje"}
              value={data.trip_date}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disabled
                label="Hora de salida"
                className="timePicker"
                id="tripTime"
                value={ticketTime}
                sx={{ backgroundColor: "rgba(0, 0, 0, 0.12)" }}
              />
            </LocalizationProvider>
            <TextInput
              disabled
              label={"Teléfono"}
              value={data.phone_number}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Oficina de registro"}
              value={data.office_name}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            {data.office_departure ? (
              <TextInput
                disabled
                label={"Oficina de abordaje"}
                value={data.office_departure}
                maxWidth={"230px"}
                maxHeight={"56px"}
              />
            ) : (
              <TextInput
                disabled
                label={"Oficina de abordaje"}
                value={data.office_name}
                maxWidth={"230px"}
                maxHeight={"56px"}
              />
            )}
            <TextInput
              disabled
              label={"Vendedor"}
              value={data.user_name}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Numero de asiento/s"}
              value={data.seats.map((seat) => seat).join(", ")}
              maxWidth={"230px"}
              maxHeight={"56px"}
            />
            <TextInput
              disabled
              label={"Precio Total"}
              value={data.total_amount}
              maxWidth={"230px"}
              maxHeight={"56px"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled
              label={"Debe"}
              value={data.amount_debt}
              maxWidth={"230px"}
              maxHeight={"56px"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              inputRef={amountPaid}
              disabled
              label={"Cantidad pagada"}
              value={data.amount_paid}
              maxWidth={"230px"}
              maxHeight={"56px"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled
              label={"Vendedor Externo"}
              value={data.external_seller_name}
              maxWidth={"230px"}
              maxHeight={"56px"}
              sx={{
                display: data.external_seller === true ? "inherit" : "none",
              }}
            />
          </TicketsContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              width: "100%",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <CreateButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              text={"Abonar"}
              onClick={handlePayButton}
            />
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              onClick={() => setOpenTicketPrint(true)}
              text={"Boleto"}
              icon={<PrintIcon />}
            />
          </div>
        </Container>
      </Slide>
    </ThemeProvider>
  );
};

export default TicketViewer;
