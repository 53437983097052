import React, { useState, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { GetAllBusses } from "../api/apiFunctions";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { CardSkeletonComponent } from "../components/skeletonComponents";
import "./editDeparture.css";
import { TextInput, SelectInputAsync, DateInput } from "../components/inputs";
import { CustomIconButton } from "../components/buttons";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LoadingScreen from "../components/loadingScreen";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TableComponent from "../components/tableComponent";
import { useMediaQuery } from "@mui/material";
import { CardTemplate } from "../components/cardComponent";

function EditDeparture() {
  const initialState = {
    bus_name: "",
    date: null,
    assigned_route: "",
    time: null,
    id: null,
  };
  const [data, setData] = useState(null);
  const [selectedBus, setSelectedBus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const [busInfo, setBusInfo] = useState(initialState);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [routesData, setRoutesData] = useState({});
  const isMobile = useMediaQuery("(max-width:767px)");

  const handleSelectChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleBusClick = (row) => {
    dayjs.extend(customParseFormat);
    setSelectedBus(row);
    const fieldsState = {
      id: row.id,
      bus_name: row.bus_name,
      date: dayjs(row.date, "DD-MM-YYYY"),
      assigned_route: {
        label: row.route_name,
        value: row.route_val,
      },
      time: dayjs(row.time, "HH:mm"),
    };
    setBusInfo(fieldsState);
    setSelectedRow(fieldsState);
  };

  const handleInputChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };
  };

  const handleInputTimeChange = (field) => {
    return (e) => {
      setBusInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingFields(true);
    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0) {
      Swal.fire({
        title: "Error",
        text: "Es necesario llenar todos los campos.",
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
      setIsLoadingFields(false);
    } else {
      let dir = {};
      Object.keys(busInfo).forEach((key) => {
        let newValue = busInfo[key];
        const oldValue = selectedRow[key];

        if (newValue !== oldValue) {
          if (key === "assigned_route") {
            dir[key] = routesData[newValue.value];
          } else if (key === "date") {
            dir[key] = newValue.format("DD-MM-YYYY");
          } else if (key === "time") {
            dir[key] = newValue.format("HH:mm");
          } else {
            dir[key] = newValue;
          }
        }
      });
      if (Object.keys(dir).length === 0) {
        Swal.fire({
          title: "Error",
          text: "Ningun campo fue cambiado",
          icon: "error",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
        });
        setIsLoadingFields(false);
      } else {
        axiosInstance
          .patch(`/bus/${selectedBus.id}/`, dir)
          .then((response) => {
            setIsLoadingFields(false);
            setBusInfo(initialState);
            Swal.fire({
              title: "Autobús modificado",
              text: "El autobús se ha modificado con éxito.",
              icon: "success",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
              willClose: () => {
                window.location.reload();
              },
            });
          })
          .catch((error) => {
            setIsLoadingFields(false);
            if (error.code === "TOKEN_EXP") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              navigate("/");
              localStorage.clear();
            } else {
              Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          });
      }
    }
  };

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        params: {
          not_started: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [navigate]);

  const loadRoutesOptions = () => {
    return axiosInstance
      .get(`/routes/`)
      .then((response) => {
        const options = response.data.map((route) => ({
          label: route.name,
          value: route.value,
        }));

        const routesIds = {};

        response.data.forEach((route) => {
          routesIds[route.value] = route.id;
        });
        setRoutesData(routesIds);
        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!busInfo.bus_name) newErrors.campo1 = "Nombre del autobus obligatorio";
    if (!busInfo.assigned_route) newErrors.campo2 = "Ruta obligatoria";
    if (!busInfo.date) newErrors.campo2 = "Fecha obligatoria";
    if (!busInfo.time) newErrors.campo1 = "Hora obligatoria";

    return newErrors;
  };

  return (
    <div className="editDepartureContainer">
      {isLoadingFields && <LoadingScreen />}
      <div className="editDepartureAvailableDepartures">
        {isLoading ? (
          <CardSkeletonComponent nRows={2} />
        ) : data ? (
          isMobile ? (
            <CardTemplate dataInfo={data} clickHandle={handleBusClick} />
          ) : (
            <TableComponent
              dataInfo={data}
              headers={["Autobus", "Fecha", "Ruta", "Hora"]}
              cellName={["bus_name", "date", "route_name", "time"]}
              clickHandle={handleBusClick}
            />
          )
        ) : (
          <></>
        )}
      </div>
      {selectedRow !== null ? (
        <div>
          <form className="departureForm">
            <TextInput
              label={"Nombre del autobus"}
              onChange={handleInputChange("bus_name")}
              value={busInfo.bus_name}
              maxWidth={"250px"}
              maxHeight={"56px"}
              minWidth={"199px"}
            />
            <DateInput
              val={busInfo.date}
              onchange={handleInputTimeChange("date")}
              maxWidth={"250px"}
              maxHeight={"56px"}
              minWidth={"199px"}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Hora de salida"
                className="timePicker"
                ampm={false}
                id="tripTime"
                value={busInfo.time}
                onChange={handleInputTimeChange("time")}
                sx={{ backgroundColor: "white" }}
              />
            </LocalizationProvider>
            <SelectInputAsync
              placeholder={"Ruta del autobus"}
              val={busInfo.assigned_route}
              onchange={handleSelectChange("assigned_route")}
              options={loadRoutesOptions}
              maxWidth={"250px"}
              maxHeight={"56px"}
              minWidth={"199px"}
            />
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              onClick={handleSubmit}
              text={"Editar Autobús"}
              icon={<ModeEditIcon />}
            />
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default EditDeparture;
