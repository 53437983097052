import Card from "react-bootstrap/Card";
import styled from "styled-components";
import React, { useState } from "react";

const Cards = styled(Card)`
  background: ${(props) =>
    props.isClicked ? "#a1e0f3 !important" : "#1990b7"};
  padding: 10px;
  border-radius: 20px;
  color: black;
  border: none;
  margin-bottom: 10px;
  width: 250px;
  flex: 0 0 auto;

  &:hover {
    background-color: #a1e0f3 !important;
    cursor: pointer;
  }
`;

export function CardTemplate({ dataInfo, clickHandle }) {
  const [clickedRowId, setClickedRowId] = useState(null);

  const handleRowClick = (row) => {
    setClickedRowId(row.id);
    clickHandle(row);
  };

  return dataInfo.data.map((row) => (
    <Cards
      key={row.id}
      isClicked={clickedRowId === row.id}
      onClick={() => handleRowClick(row)}
    >
      <Cards.Body>
        <Cards.Text style={{ marginTop: "0px !important" }}>
          <span style={{ fontWeight: "bold" }}>Autobus:</span>
          {` ${row.bus_name}`}
          <br />
          <span style={{ fontWeight: "bold" }}>Ruta:</span>
          {` ${row.route_name}`}
          <br />
          <span style={{ fontWeight: "bold" }}>Fecha:</span>
          {` ${row.date}`}
          <br />
          <span style={{ fontWeight: "bold" }}>Hora:</span>
          {` ${row.time}`}
          <br />
        </Cards.Text>
      </Cards.Body>
    </Cards>
  ));
}

export function EmptyCardTemplate({ dataInfo, onClick, icon, title_value }) {
  const [clickedRowId, setClickedRowId] = useState(null);

  const handleRowClick = (row) => {
    setClickedRowId(row.id);
    onClick(row.id);
  };
  return dataInfo.map((row) => (
    <Cards
      key={row.id}
      isClicked={clickedRowId === row.id}
      style={{
        borderRadius: "20px",
        maxWidth: "150px",
        backgroundColor: "white",
      }}
      onClick={() => handleRowClick(row)}
    >
      <Cards.Body>
        <Cards.Text>
          {icon && <span>{icon}</span>}
          <br />
          <span style={{ fontWeight: "bold" }}>{row[title_value]}</span>
        </Cards.Text>
      </Cards.Body>
    </Cards>
  ));
}
