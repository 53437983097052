import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import SeatInfoLabel from "./seatInfoLabel";
import { CustomIconButton } from "./buttons";
import Swal from "sweetalert2";
import axiosInstance from "../interceptors/axiosInstance";
import { useNavigate } from "react-router-dom";

const BusSeatsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  justify-content: ${(props) =>
    props.isLastRow ? "flex-start" : "space-around"};
`;

const SeatPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  width: fit-content;
`;

const EmptySeat = styled.div`
  width: 20px;
  margin: 5px;
`;

const BusSeat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${(props) =>
    props.isEmpty ? (props.isSelected ? "white" : "inherit") : "grey"};
  pointer-events: ${(props) => (props.isEmpty ? "auto" : "none")};
  background-color: ${(props) =>
    props.isTicketSeat ? "#19b78f" : props.isSelected ? "#1990b7" : ""};
  padding: ${(props) => (props.isLastRow ? "0px 18px" : "0px 18px")};
  &:hover {
    color: ${(props) => (props.isSelected ? "" : "#1990b7")};
    cursor: pointer;
  }
`;

function SeatsPopUp(props) {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedSeatCounter, setSelectedSeatCounter] = useState(0);
  const numberOfSeats = props.seatsNumbers.length;
  const navigate = useNavigate();

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const handleSeatClick = (seat_id) => {
    setSelectedSeats((prevSelectedSeats) => {
      if (prevSelectedSeats.includes(seat_id)) {
        setSelectedSeatCounter(selectedSeatCounter - 1);
        return prevSelectedSeats.filter((seatId) => seatId !== seat_id);
      } else {
        if (selectedSeatCounter < numberOfSeats) {
          setSelectedSeatCounter(selectedSeatCounter + 1);
          return [...prevSelectedSeats, seat_id];
        }
        return prevSelectedSeats;
      }
    });
  };

  const handleEditSeats = () => {
    Swal.fire({
      title: "Atencion",
      text: "¿Seguro que desea cambiar los numeros de asientos del boleto?",
      showDenyButton: true,
      icon: "warning",
      confirmButtonText: "Si",
      denyButtonText: "Cancelar",
      confirmButtonColor: "#0da290",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .patch(`/tickets/${props.ticketID}/`, { seats: selectedSeats })
          .then((response) => {
            Swal.fire({
              title: "Exito",
              text: "Exito",
              icon: "success",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
            window.location.reload();
          })
          .catch((error) => {
            if (error.code === "TOKEN_EXP") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              navigate("/");
              localStorage.clear();
            } else {
              Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const seatChunks = chunkArray(props.seatsData, 4);
  return (
    <div className="popup">
      <div className="popup-inner">
        <h2 style={{ margin: "0px" }}>Seleccione los nuevos asientos</h2>
        <SeatInfoLabel text={"Asientos del boleto"} color={"#19b78f"} />
        <SeatInfoLabel
          text={"Asientos nuevos seleccionados"}
          color={"#1990b7"}
        />
        <SeatPickerContainer>
          {seatChunks.map((chunk, rowIndex) => (
            <BusSeatsRow
              key={rowIndex}
              isLastRow={rowIndex === seatChunks.length - 1}
            >
              {chunk.map((seat, index) => (
                <React.Fragment key={seat.id}>
                  <BusSeat
                    isEmpty={seat.is_empty}
                    isSelected={selectedSeats.includes(seat.id)}
                    onClick={() => handleSeatClick(seat.id)}
                    isTicketSeat={props.seatsNumbers.includes(seat.seat_number)}
                    isLastRow={rowIndex === seatChunks.length - 1}
                  >
                    <EventSeatIcon />
                    <p style={{ margin: "0px" }}>{seat.seat_number}</p>
                  </BusSeat>
                  {index % 2 === 1 && index !== chunk.length - 1 && (
                    <EmptySeat />
                  )}
                </React.Fragment>
              ))}
            </BusSeatsRow>
          ))}
        </SeatPickerContainer>
        <CustomIconButton
          maxWidth={"230px"}
          maxHeight={"56px"}
          text={"Cambiar asientos"}
          disabled={numberOfSeats !== selectedSeatCounter}
          onClick={handleEditSeats}
        />
        <CustomIconButton
          maxWidth={"230px"}
          maxHeight={"56px"}
          text={"Cerrar"}
          onClick={props.toggle}
        />
      </div>
    </div>
  );
}

export default SeatsPopUp;
