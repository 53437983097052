import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const SearchButton = React.forwardRef((props, ref) => {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#1990b7",
            maxWidth: props.maxWidth,
            minWidth: props.minWidth,
            width: "100%",
            height: props.maxHeight,
            "&:hover": {
              backgroundColor: "#136d8a",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        ref={ref}
        onClick={props.onClick}
        variant="contained"
        endIcon={<SearchIcon />}
      >
        Buscar
      </Button>
    </ThemeProvider>
  );
});

export function DeleteFilterButton({ onClick, maxWidth, maxHeight, minWidth }) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            minWidth: minWidth,
            backgroundColor: "#1990b7",
            maxWidth: maxWidth,
            height: maxHeight,
            fontSize: "clamp(12px, 1vw, 16px)",
            width: "100%",
            "&:hover": {
              backgroundColor: "#136d8a",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        variant="contained"
        endIcon={<FormatListBulletedIcon />}
      >
        Ver todo
      </Button>
    </ThemeProvider>
  );
}

export function CreateButton({
  onClick,
  maxWidth,
  maxHeight,
  disabled = false,
  text,
  sx,
}) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#1990b7",
            maxWidth: maxWidth,
            width: "100%",
            height: maxHeight,
            "&:hover": {
              backgroundColor: "#136d8a",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        sx={sx}
        disabled={disabled}
        onClick={onClick}
        variant="contained"
        endIcon={<AddIcon />}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
}

export function CustomIconButton({
  onClick,
  maxWidth,
  maxHeight,
  disabled = false,
  text,
  sx,
  icon,
  bColor = "#1990b7",
  bColorHover = "#136d8a",
}) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            backgroundColor: bColor,
            maxWidth: maxWidth,
            width: "100%",
            height: maxHeight,
            fontSize: "clamp(12px, 1vw, 16px)",
            "&:hover": {
              backgroundColor: bColorHover,
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        sx={sx}
        disabled={disabled}
        onClick={onClick}
        variant="contained"
        endIcon={icon}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
}
