import React from "react";
import EventSeatIcon from "@mui/icons-material/EventSeat";

function SeatInfoLabel(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          padding: "5px",
          backgroundColor: props.color,
        }}
      >
        <EventSeatIcon />
      </div>
      <label>{props.text}</label>
    </div>
  );
}

export default SeatInfoLabel;
