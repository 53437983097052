import React, { useEffect, useState } from "react";
import axiosInstance from "../interceptors/axiosInstance";
import "./departureSearcher.css";
import Swal from "sweetalert2";
import TableComponent from "../components/tableComponent";
import { useNavigate } from "react-router-dom";
import SeatSelectorComponent from "../components/seatSelectorComponent";
import { toast, Bounce } from "react-toastify";
import { GetAllBusses } from "../api/apiFunctions";
import { CardTemplate } from "../components/cardComponent";
import {
  TableSkeletonComponent,
  CardSkeletonComponent,
} from "../components/skeletonComponents";
import { DateInput, SelectInputAsync } from "../components/inputs";
import { SearchButton, DeleteFilterButton } from "../components/buttons";
import LoadingScreen from "../components/loadingScreen";

const DepartureSearcher = () => {
  const [route, setRoute] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [seatsAreLoading, setSeatsAreLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [seats, setSeats] = useState(null);
  const [selectedBus, setSelectedBus] = useState(null);

  const loadRoutesOptions = () => {
    return axiosInstance
      .get(`/routes/`)
      .then((response) => {
        const options = response.data.map((route) => ({
          label: route.name,
          value: route.value,
        }));
        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleBusClick = (row) => {
    setSeats(null);
    setSeatsAreLoading(true);
    setSelectedBus(row);
    axiosInstance
      .get(`/bus/${row.id}/get_available_seats/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSeats(response.data);
        setSeatsAreLoading(false);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        } else {
          if (error.code === "ERR_BAD_REQUEST") {
            if (error.response.data.code === "token_not_valid") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              localStorage.clear();
              navigate("/");
            }
          } else {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
          }
        }
      });
  };

  const handleSearch = () => {
    if (selectedDate === null || route === "") {
      toast.error("Se debe seleccionar fecha y ruta", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      setSeats(null);
      setSelectedBus(null);
      GetAllBusses(
        "/bus/",
        (response) => {
          setData(response);
        },
        (loading) => {
          setIsLoading(loading);
        },
        (path) => {
          navigate(path);
        },
        {
          params: {
            date: selectedDate.format("YYYY-MM-DD"),
            requested_route: route,
          },
        }
      );
    }
  };

  const handleSearchAll = () => {
    setSeats(null);
    setSelectedBus(null);
    setRoute("");
    setSelectedDate(null);
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        params: {
          not_started: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    setSeats(null);
    setSelectedBus(null);
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        params: {
          not_started: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [navigate]);

  return (
    <div className="departureSearcherContainer">
      <div className="departureSearchBarContainer">
        <DateInput
          val={selectedDate}
          onchange={(e) => setSelectedDate(e)}
          maxWidth={"250px"}
          maxHeight={"50px"}
          minWidth={"199px"}
        />
        <SelectInputAsync
          placeholder={"Ruta"}
          val={route}
          onchange={(e) => setRoute(e)}
          maxWidth={"250px"}
          maxHeight={"50px"}
          options={loadRoutesOptions}
          minWidth={"199px"}
        />
        <SearchButton
          onClick={handleSearch}
          maxWidth={"200px"}
          maxHeight={"50px"}
          minWidth={"115px"}
          ref={null}
        />
        <DeleteFilterButton
          onClick={handleSearchAll}
          maxWidth={"200px"}
          maxHeight={"50px"}
          minWidth={"115px"}
        />
      </div>
      <div className="departureSearchBarMobile">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <DateInput
            val={selectedDate}
            onchange={(e) => setSelectedDate(e)}
            maxWidth={"250px"}
            maxHeight={"50px"}
            minWidth={"199px"}
          />
          <SelectInputAsync
            placeholder={"Ruta"}
            val={route}
            onchange={(e) => setRoute(e)}
            maxWidth={"250px"}
            maxHeight={"50px"}
            options={loadRoutesOptions}
            minWidth={"199px"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <SearchButton
            onClick={handleSearch}
            maxWidth={"200px"}
            maxHeight={"50px"}
            minWidth={"115px"}
            ref={null}
          />
          <DeleteFilterButton
            onClick={handleSearchAll}
            maxWidth={"200px"}
            maxHeight={"50px"}
            minWidth={"115px"}
          />
        </div>
      </div>
      <div className="departureContentContainer">
        <h1 style={{ marginTop: "0px" }}>Salidas disponibles</h1>
        {isLoading ? (
          <TableSkeletonComponent nRows={6} />
        ) : (
          data && (
            <TableComponent
              dataInfo={data}
              headers={["Autobus", "Fecha", "Ruta", "Hora"]}
              cellName={["bus_name", "date", "route_name", "time"]}
              clickHandle={handleBusClick}
            />
          )
        )}
      </div>
      <div className="departureContentMobileContainer">
        {isLoading ? (
          <CardSkeletonComponent nRows={2} />
        ) : (
          data && <CardTemplate dataInfo={data} clickHandle={handleBusClick} />
        )}
      </div>
      {seats ? (
        <SeatSelectorComponent seatsData={seats} busInfo={selectedBus} />
      ) : seatsAreLoading ? (
        <LoadingScreen />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DepartureSearcher;
