import React, { useEffect, useState } from "react";
import "./deleteUser.css";
import axiosInstance from "../interceptors/axiosInstance";
import { EmptyCardTemplate } from "../components/cardComponent";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { CustomIconButton } from "../components/buttons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

function DeleteUser() {
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get("/users/")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {});
  }, []);

  const handleDeleteUser = () => {
    Swal.fire({
      title: "Atención",
      text: `¿Esta seguro de eliminar el usuario seleccionado?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axiosInstance
          .delete(`/users/${selectedUser}/`)
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              Swal.fire({
                title: "Usuario eliminado",
                text: `El usuariuo se ha eliminado exitosamente.`,
                icon: "success",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
                willClose: () => {
                  window.location.reload();
                },
              });
            } else {
              Swal.fire({
                title: "ERROR",
                text: response.data.message,
                icon: "success",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code === "TOKEN_EXP") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              localStorage.clear();
              navigate("/");
            } else {
              Swal.fire({
                title: "Error",
                text: error.response.data.detail,
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          });
      }
    });
  };

  return (
    <div className="deleteUserContainer">
      <div className="deleteUserList">
        {users && (
          <EmptyCardTemplate
            dataInfo={users}
            title_value={"username"}
            icon={<PermIdentityIcon />}
            onClick={setSelectedUser}
          />
        )}
      </div>
      <CustomIconButton
        onClick={() => {
          handleDeleteUser();
        }}
        maxWidth={"200px"}
        maxHeight={"56px"}
        minWidth={"115px"}
        text={"Borrar usuario"}
        icon={<DeleteSweepIcon />}
        disabled={selectedUser === null}
      />
      {isLoading && <LoadingScreen />}
    </div>
  );
}

export default DeleteUser;
