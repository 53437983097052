import React, { useState, useEffect } from "react";
import "./editTickets.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptors/axiosInstance";
import TicketEdit from "../components/ticketEdit";
import { GetAllBusses } from "../api/apiFunctions";
import { CardSkeletonComponent } from "../components/skeletonComponents";
import { CardTemplate } from "../components/cardComponent";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import TableComponent from "../components/tableComponent";
import { useMediaQuery } from "@mui/material";

const Cards = styled(Card)`
  background: ${(props) =>
    props.isClicked ? "#a1e0f3 !important" : "#1990b7"};
  color: black;
  border: none;
  margin-bottom: 10px;
  flex: 0 0 auto;
  min-width: 200px;
  padding: 10px;
  border-radius: 20px;
  flex-wrap: wrap;

  &:hover {
    background-color: #a1e0f3 !important;
    cursor: pointer;
  }
`;
const EditTickets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [officeOptions, setOfficeOptions] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [ticketInformation, setTicketInformation] = useState(null);
  const navigate = useNavigate();
  const [busId, setBusId] = useState(null);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [ticketsInformation, setTicketsInformation] = useState(null);
  const [clickedRowId, setClickedRowId] = useState(null);
  const [seats, setSeats] = useState(null);
  const isMobile = useMediaQuery("(max-width:767px)");

  const handleBusClick = (row) => {
    setIsLoadingTickets(true);
    axiosInstance
      .get(`/tickets/`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          bus: row.id,
        },
      })
      .then((response) => {
        setIsLoadingTickets(false);
        if (response.status === 204) {
          Swal.fire({
            title: "Advertencia",
            text: "No se encontraron boletos del autobus seleccionado.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        } else {
          setBusId(row);
          setTicketsInformation(response);
        }
      })
      .catch((error) => {
        setIsLoadingTickets(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });

    axiosInstance
      .get(`/bus/${row.id}/get_available_seats/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSeats(response.data);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        } else {
          if (error.code === "ERR_BAD_REQUEST") {
            if (error.response.data.code === "token_not_valid") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              localStorage.clear();
              navigate("/");
            }
          } else {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
              iconColor: "#1ccda0",
              confirmButtonColor: "#0da290",
            });
          }
        }
      });
  };

  const handleRowClick = (row) => {
    setClickedRowId(row.id);
    setTicketInformation(null);
    axiosInstance
      .get(`/tickets/${row.id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTicketInformation(response);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          many: false,
        },
      }
    );

    axiosInstance
      .get(`/offices/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((office) => ({
          label: office.name,
          value: office.id,
        }));
        setOfficeOptions(options);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });

    axiosInstance
      .get(`/payment/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((row) => ({
          label: row.name,
          value: row.id,
        }));
        setPaymentOptions(options);
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  }, [navigate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label>1- Seleccione una salida para ver los boletos asociados</label>
      <div className="editDepartureAvailableDepartures">
        {isLoading ? (
          <CardSkeletonComponent nRows={2} />
        ) : data ? (
          isMobile ? (
            <CardTemplate dataInfo={data} clickHandle={handleBusClick} />
          ) : (
            <TableComponent
              dataInfo={data}
              headers={["Autobus", "Fecha", "Ruta", "Hora"]}
              cellName={["bus_name", "date", "route_name", "time"]}
              clickHandle={handleBusClick}
            />
          )
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: busId ? "flex" : "none",
          flexDirection: "column",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <label>2- Seleccione un boleto para editarlo</label>

        <div className="editDepartureAvailableDepartures">
          {isLoadingTickets ? (
            <CardSkeletonComponent nRows={2} />
          ) : (
            ticketsInformation &&
            ticketsInformation.data
              .sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              })
              .map((row) => (
                <Cards
                  key={row.id}
                  isClicked={clickedRowId === row.id}
                  onClick={() => handleRowClick(row)}
                >
                  <Cards.Body>
                    <Cards.Text>
                      <span>Nombre: {row.name}</span>
                      <br />
                      <span>Apellido(s): {row.last_names}</span>
                      <br />
                      <span>Asiento(s): {row.seats.join(", ")}</span>
                      <br />
                    </Cards.Text>
                  </Cards.Body>
                </Cards>
              ))
          )}
        </div>

        {ticketInformation && (
          <TicketEdit
            data={ticketInformation.data}
            officeOptions={officeOptions}
            paymentOptions={paymentOptions}
            seats={seats}
          />
        )}
      </div>
    </div>
  );
};

export default EditTickets;
