import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./administrationPanel.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";

const AdministrationPanel = () => {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case "1":
        navigate("salidas");
        break;
      case "2":
        navigate("boletos");
        break;
      case "3":
        navigate("usuarios");
        break;
      case "4":
        navigate("rutas");
        break;
      case "5":
        navigate("oficinas");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    navigate("salidas");
  }, [navigate]);

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            ".MuiTabs-indicator": {
              backgroundColor: "#1990b7",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            width: "200px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="administrationPanelContainer">
        <h1 style={{ marginTop: "0" }}>Panel de administración</h1>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ marginBottom: "20px", borderBottom: "1px solid #cfcfcf" }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            icon={<DirectionsBusIcon />}
            iconPosition="end"
            value={"1"}
            label="Salidas"
          />
          <Tab
            icon={<ConfirmationNumberIcon />}
            iconPosition="end"
            value={"2"}
            label="Boletos"
          />
          <Tab
            icon={<AccountCircleIcon />}
            iconPosition="end"
            value={"3"}
            label="Usuarios"
          />
          <Tab
            icon={<AltRouteIcon />}
            iconPosition="end"
            value={"4"}
            label="Rutas"
          />
          <Tab
            icon={<MapsHomeWorkIcon />}
            iconPosition="end"
            value={"5"}
            label="Oficinas"
          />
        </Tabs>
        <div style={{ height: "100%" }}>
          <Outlet />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AdministrationPanel;
