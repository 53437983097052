import React, { useState } from "react";
import { TextInput } from "../components/inputs";
import { CreateButton } from "../components/buttons";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";

function CreateRoute() {
  const [route, setRoute] = useState("");
  const [destinations, setDestinations] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddDestination = () => {
    setDestinations([...destinations, ""]);
  };

  const handleDestinationChange = (index, value) => {
    const newDestinations = [...destinations];
    newDestinations[index] = value;
    setDestinations(newDestinations);
  };

  const createRoute = () => {
    setIsLoading(true);
    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const fullRoute = [route, ...destinations]
      .map((destination) => destination.split("-").map(capitalize).join("-"))
      .join("-");

    const initialsRoute = [route, ...destinations]
      .map((destination) =>
        destination
          .split("-")
          .map((word) => word.charAt(0).toUpperCase())
          .join("-")
      )
      .join("-");

    axiosInstance
      .post(
        "/routes/",
        {
          name: fullRoute,
          value: initialsRoute,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        Swal.fire({
          title: "Ruta creada",
          text: "La ruta se ha creado correctamente.",
          icon: "success",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
          willClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        } else {
          Swal.fire({
            title: "Error",
            text: "Algún dato ingresado es incorrecto.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: "30px",
      }}
    >
      {isLoading && <LoadingScreen />}
      <label>Se debe definir por lo menos un destino</label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        <TextInput
          label={"Origen"}
          value={route}
          onChange={(e) => setRoute(e.target.value)}
          maxWidth={"250px"}
          maxHeight={"56px"}
        />

        {destinations.map((destination, index) => (
          <TextInput
            key={index}
            label={`Destino ${index + 1}`}
            value={destination}
            onChange={(e) => handleDestinationChange(index, e.target.value)}
            maxWidth={"250px"}
            maxHeight={"56px"}
            style={{ marginBottom: "10px" }}
          />
        ))}
        <CreateButton
          maxWidth={"250px"}
          maxHeight={"56px"}
          text={"Agregar Destino"}
          onClick={handleAddDestination}
        />
        <CreateButton
          disabled={route === ""}
          maxWidth={"250px"}
          maxHeight={"56px"}
          text={"Crear"}
          onClick={createRoute}
        />
      </div>
    </div>
  );
}

export default CreateRoute;
