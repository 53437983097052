import React, { useState } from "react";
import { TextInput } from "../components/inputs";
import { CreateButton } from "../components/buttons";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";

function CreateOffice() {
  const [officeName, setOfficeName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const createRoute = () => {
    setIsLoading(true);
    axiosInstance
      .post("/offices/", {
        name:
          officeName.charAt(0).toUpperCase() +
          officeName.slice(1).toLowerCase(),
      })
      .then((response) => {
        setIsLoading(false);
        Swal.fire({
          title: "Oficina creada",
          text: "La oficina se ha creado correctamente.",
          icon: "success",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
          willClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        } else {
          Swal.fire({
            title: "Error",
            text: "Error al intentar crear la ruta.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "30px",
        flexWrap: "wrap",
      }}
    >
      {isLoading && <LoadingScreen />}
      <label style={{ width: "auto" }}>
        Ingrese el nombre de la oficina a registrar
      </label>
      <TextInput
        label={"Nombre de la oficina"}
        onChange={(e) => setOfficeName(e.target.value)}
        value={officeName}
        maxWidth={"250px"}
        maxHeight={"56px"}
        minWidth={"199px"}
      />
      <CreateButton
        onClick={createRoute}
        maxWidth={"250px"}
        maxHeight={"56px"}
        disabled={officeName === ""}
        text={"Crear oficina"}
      />
    </div>
  );
}

export default CreateOffice;
