import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { CustomIconButton } from "../components/buttons";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ReactToPrint from "react-to-print";
import ImageTicket from "../components/imageTicket";
import { toPng } from "html-to-image";
import download from "downloadjs";

const TicketWrapper = styled.div`
  background-color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  border: 2px solid #000;
  border-radius: 5px;
  box-shadow: none;
  display: none;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  @media print {
    margin: -5mm !important;
    display: flex !important;
  }
`;

const Header = styled.div`
  text-align: center;
  border-bottom: 2px dashed #000;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const Title = styled.h1`
  font-size: 18px;
  margin: 0;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 5px;
`;

const InfoItem = styled.p`
  margin: 3px 0;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #000;
`;

const Separator = styled.p`
  text-align: center;
  margin: 10px 0;
  color: #333;
`;

const Footer = styled.div`
  text-align: center;
  border-top: 2px dashed #000;
  padding-top: 5px;
  margin-top: 5px;
`;

const formatDate = (fecha) => {
  const [year, month, day] = fecha.split("-");
  const fechaObjeto = new Date(year, month - 1, day);
  const opcionesMes = { month: "long" };

  let nombreDelMes = fechaObjeto.toLocaleDateString("es-ES", opcionesMes);

  nombreDelMes = nombreDelMes.charAt(0).toUpperCase() + nombreDelMes.slice(1);

  return `${day} de ${nombreDelMes} de ${year}`;
};

const PrintTicketPopUp = ({
  ticketData,
  closeMethod = null,
  reloadWindow = false,
}) => {
  const ticketRef = useRef();
  const imageRef = useRef();

  const handleDownloadImage = () => {
    const scale = 2;
    if (imageRef.current === null) {
      return;
    }
    const node = imageRef.current;

    toPng(node, {
      cacheBust: true,
      width: node.scrollWidth * scale,
      height: node.scrollHeight * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: `${node.scrollWidth}px`,
        height: `${node.scrollHeight}px`,
      },
    })
      .then((dataUrl) => {
        download(
          dataUrl,
          `boleto-de-${ticketData.name}-${ticketData.last_names}.png`
        );
      })
      .catch((err) => {
        console.error("Error al generar la imagen:", err);
      });
  };

  const handleClose = () => {
    document.body.classList.remove("no-scroll");

    if (reloadWindow === true) {
      window.location.reload();
    } else {
      closeMethod(false);
    }
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner">
        <h1 style={{ textAlign: "center" }}>Boleto generado</h1>
        <TicketWrapper ref={ticketRef}>
          <Header>
            <Title>Viajes del Sur</Title>
          </Header>
          <Section>
            <InfoItem>
              <span>
                Boleto de {ticketData.name} {ticketData.last_names}
              </span>
            </InfoItem>
            <InfoItem>
              <span>Origen: </span>
              <span>{ticketData.origin}</span>
            </InfoItem>
            <InfoItem>
              <span>Destino: </span>
              <span>{ticketData.destiny}</span>
            </InfoItem>
            <InfoItem>
              <span>Teléfono: </span>
              <span>{ticketData.phone_number}</span>
            </InfoItem>
            <InfoItem>
              <span>Fecha de viaje: </span>
              <span>{formatDate(ticketData.trip_date)}</span>
            </InfoItem>
            <InfoItem>
              <span>Hora de viaje: </span>
              <span>{ticketData.time.slice(0, 5)} Hrs</span>
            </InfoItem>
          </Section>
          <Separator>----------------------------</Separator>
          <Section>
            <InfoItem>
              <span>Oficina de registro: </span>
              <span>{ticketData.office_name}</span>
            </InfoItem>
            <InfoItem>
              <span>Lugar de abordaje: </span>
              {ticketData.office_departure ? (
                <span>{ticketData.office_departure}</span>
              ) : (
                <span>{ticketData.office_name}</span>
              )}
            </InfoItem>
            <InfoItem>
              <span>Fecha: </span>
              <span>{new Date().toLocaleDateString()}</span>
            </InfoItem>
            <InfoItem>
              <span>Hora: </span>
              <span>{new Date().toLocaleTimeString()}</span>
            </InfoItem>
            <InfoItem>
              <span>Vendedor: </span>
              <span>{ticketData.user_name}</span>
            </InfoItem>
          </Section>
          <Separator>----------------------------</Separator>
          <Section>
            <InfoItem>
              <span>Cantidad abonada: </span>
              <span>${ticketData.amount_paid}</span>
            </InfoItem>
            <InfoItem>
              <span>Precio total: </span>
              <span>${ticketData.total_amount}</span>
            </InfoItem>
            <InfoItem>
              <span>Debe: </span>
              <span>${ticketData.amount_debt}</span>
            </InfoItem>
            <InfoItem>
              <span>Numero de asientos: </span>
              {ticketData.seats.map((seat) => seat).join(", ")}
            </InfoItem>
          </Section>
          <Footer>
            Tiene derecho a 25 kilos de equipaje (no incluye electrónicos), si
            usted trae exceso de equipaque se le cobrará. El ticket de abordaje
            será válido unicamente para el destino, fecha y hora de salida, no
            hay devoluciones. Favor de estar 30 minutos antes de la hora de
            salida.
            <p>Informes o dudas: 656178309 / 6561858132 / 6564059239</p>
          </Footer>
        </TicketWrapper>
        <div ref={imageRef}>
          <ImageTicket {...ticketData} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <ReactToPrint
            trigger={() => (
              <CustomIconButton
                maxWidth={"230px"}
                maxHeight={"56px"}
                text={"Imprimir ticket"}
                icon={<PrintIcon />}
              />
            )}
            content={() => ticketRef.current}
          />
          <CustomIconButton
            maxWidth={"230px"}
            maxHeight={"56px"}
            text={"Descargar boleto digital"}
            icon={<PrintIcon />}
            onClick={handleDownloadImage}
          />
          <CustomIconButton
            maxWidth={"230px"}
            maxHeight={"56px"}
            text={"Cerrar"}
            icon={<CloseIcon />}
            bColor="#A52A2A"
            bColorHover="#761e1e"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintTicketPopUp;
