import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import axiosInstance from "../interceptors/axiosInstance";
import { Outlet } from "react-router-dom";
import "./rootPage.css";
import LogoPic from "../assets/images/Logo.png";
import { Squash as Hamburger } from "hamburger-react";
import { useLocation } from "react-router-dom";

const Logo = styled.div`
  max-width: 100px;
  width: 100%;
`;

const NavItem = styled(NavLink)`
  width: calc(100% - 20px);
  padding: 15px 0px;
  margin: 5px 0px;
  text-decoration: none;
  text-align: center;
  color: black;

  &.active {
    background-color: #e7f7fc;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left: 20px;
    font-weight: bold;
    color: black;
  }

  &:hover:not(.active) {
    text-decoration: underline solid 2px;
  }
`;

const RootPage = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const getLinkClass = (path) => {
    return location.pathname === path ? "active" : "";
  };
  const handleClickLogout = () => {
    axiosInstance
      .post(
        "/logout/",
        {
          refresh_token: localStorage.getItem("refresh_token"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No se pudo cerrar sesion",
          icon: "error",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
        });
      });
  };

  const logOut = () => {
    Swal.fire({
      title: "Atención",
      text: "¿Esta seguro de cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickLogout();
      }
    });
  };

  return (
    <>
      <div className="mainContainerDesktop">
        <div className="sideBarContainer">
          <Logo>
            <img src={LogoPic} className="main_logo" alt="CompanyLogo" />
          </Logo>
          <NavItem to="home" exact activeClassName="active">
            Inicio
          </NavItem>
          <NavItem to="buscar-salida" exact activeClassName="active">
            Buscar Salida
          </NavItem>
          <NavItem to="buscar-cliente" activeClassName="active">
            Buscar Pasajero
          </NavItem>
          <NavItem to="reportes" activeClassName="active">
            Reportes
          </NavItem>
          {localStorage.getItem("is_superuser") !== "false" && (
            <NavItem to="administracion" activeClassName="active">
              Administracion
            </NavItem>
          )}
          <button className="logOutButton" onClick={() => logOut()}>
            Cerrar Sesión
          </button>
        </div>
        <div>
          <div className="headerMobile">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
            <Logo>
              <img
                src={LogoPic}
                className="mobile_logo"
                alt="CompanyLogo"
              ></img>
            </Logo>
          </div>
          {isOpen && (
            <div className="navItems">
              <NavLink
                to="home"
                className={getLinkClass("/home")}
                onClick={() => setOpen(false)}
              >
                Inicio
              </NavLink>

              <NavLink
                to="buscar-salida"
                className={getLinkClass("/buscar-salida")}
                onClick={() => setOpen(false)}
              >
                Buscar Salida
              </NavLink>
              <NavLink
                to="buscar-cliente"
                className={getLinkClass("/buscar-cliente")}
                onClick={() => setOpen(false)}
              >
                Buscar Pasajero
              </NavLink>

              <NavLink
                to="reportes"
                className={getLinkClass("/reportes")}
                onClick={() => setOpen(false)}
              >
                Reportes
              </NavLink>

              {localStorage.getItem("is_superuser") !== "false" && (
                <NavLink
                  to="administracion"
                  className={getLinkClass("/administracion")}
                  onClick={() => setOpen(false)}
                >
                  Administracion
                </NavLink>
              )}
              <button className="logOutButton" onClick={() => logOut()}>
                Cerrar Sesión
              </button>
            </div>
          )}
        </div>
        <div className="detailContent">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default RootPage;
