import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import TextField from "@mui/material/TextField";

export function DateInput({
  val,
  onchange,
  maxWidth,
  maxHeight,
  minWidth,
  disabled,
}) {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            width: "100%",
            maxWidth: maxWidth,
            height: "100%",
            maxHeight: maxHeight,
            minWidth: minWidth,
            ".Mui-disabled": {
              "-webkit-text-fill-color": "black",
              cursor: "not-allowed",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins, sans-serif",
            color: "black",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "100%",
            width: "100%",
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            ".Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={val}
          onChange={onchange}
          label="Fecha"
          format="DD/MM/YYYY"
          disabled={disabled}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export function SelectInput({
  placeholder,
  onChange,
  val,
  maxHeight,
  maxWidth,
  options,
  disabled,
  defaultValue = 0,
}) {
  const selectStyle = {
    container: (baseStyles) => ({
      ...baseStyles,
      width: "100%",
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      backgroundColor: "white",
      cursor: disabled ? "not-allowed !important" : "pointer",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      height: maxHeight,
      maxHeight: maxHeight,
      borderColor: "black",
      backgroundColor: disabled ? "rgba(0, 0, 0, 0.12)" : "inherit",
      "&:hover": { boxShadow: "none" },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "black",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      marginTop: "0px !important",
    }),
  };
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      styles={selectStyle}
      value={val}
      options={options}
      defaultValue={options[defaultValue]}
      isDisabled={disabled}
    />
  );
}

export function SelectInputAsync({
  options,
  placeholder,
  onchange,
  maxHeight,
  maxWidth,
  val,
  minWidth,
  disabled = false,
}) {
  const selectStyle = {
    container: (baseStyles) => ({
      ...baseStyles,
      width: "100%",
      maxWidth: maxWidth,
      minWidth: minWidth,
      backgroundColor: "white",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isDisabled ? "rgba(0, 0, 0, 0.12)" : "white",
      height: maxHeight,
      maxHeight: maxHeight,
      borderColor: "black",
      "&:hover": { boxShadow: "none" },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "black",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      marginTop: "0px !important",
    }),
  };
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={options}
      defaultOptions
      placeholder={placeholder}
      styles={selectStyle}
      onChange={onchange}
      value={val}
      isDisabled={disabled}
    />
  );
}

export function TextInput({
  label,
  onChange,
  value,
  maxWidth,
  onKeyDown = null,
  InputProps = null,
  disabled,
  maxHeight,
  minWidth,
  sx = null,
  type = null,
}) {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            minWidth: minWidth,
            color: "black",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            width: "100%",
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            height: "100%",
            ".Mui-disabled": {
              "-webkit-text-fill-color": "black",
              cursor: "not-allowed",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={label}
        onChange={onChange}
        value={value}
        variant="filled"
        onKeyDown={onKeyDown}
        InputProps={InputProps}
        disabled={disabled}
        sx={sx}
        type={type}
      />
    </ThemeProvider>
  );
}
