import React, { useState } from "react";
import "./administrationPanels.css";
import DeleteTickets from "./deleteTickets";
import { CSSTransition } from "react-transition-group";
import EditTickets from "./editTickets";

function TicketAdministration() {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  return (
    <div className="mainContainer">
      <h3
        onClick={() => setShowDelete(!showDelete)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showDelete ? "0px" : "20px",
        }}
      >
        Eliminar boletos {showDelete ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showDelete}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <DeleteTickets />
        </div>
      </CSSTransition>
      <h3
        onClick={() => setShowEdit(!showEdit)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showEdit ? "0px" : "20px",
        }}
      >
        Editar boletos{showEdit ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showEdit}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <EditTickets />
        </div>
      </CSSTransition>
    </div>
  );
}

export default TicketAdministration;
