import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import DepartureSearcher from "./routes/departureSearcher";
import RootPage from "./routes/rootPage";
import Home from "./routes/home";
import CustomerSearcher from "./routes/customerSearcher";
import GenerateReports from "./routes/generateReports";
import DepartureAdministration from "./routes/departureAdministration";
import Login from "./routes/login";
import AdministrationPanel from "./routes/administrationPanel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditUsers from "./routes/usersAdministration";
import "./App.css";
import RoutesAdminsitration from "./routes/routesAdminsitration";
import TicketAdministration from "./routes/ticketAdministration";
import OfficesAdministration from "./routes/officesAdministration";

const App = () => {
  const router = createBrowserRouter([
    { path: "/", element: <Navigate replace to="/login" /> },
    { path: "/login", element: <Login /> },
    {
      path: "/menu",
      element: <RootPage />,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "buscar-salida",
          element: <DepartureSearcher />,
        },
        {
          path: "buscar-cliente",
          element: <CustomerSearcher />,
        },
        {
          path: "reportes",
          element: <GenerateReports />,
        },
        {
          path: "administracion",
          element: <AdministrationPanel />,
          children: [
            {
              path: "salidas",
              element: <DepartureAdministration />,
            },
            {
              path: "boletos",
              element: <TicketAdministration />,
            },
            {
              path: "usuarios",
              element: <EditUsers />,
            },
            {
              path: "rutas",
              element: <RoutesAdminsitration />,
            },
            {
              path: "oficinas",
              element: <OfficesAdministration />,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default App;
