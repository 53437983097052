import React from "react";
import { Skeleton } from "@mui/material";

export const TableSkeletonComponent = ({ nRows }) => {
  var indents = [];
  for (var i = 0; i < nRows; i++) {
    indents.push(
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={40}
        animation="wave"
        style={{ marginBottom: 6 }}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {indents}
    </div>
  );
};

export const CardSkeletonComponent = ({ nRows }) => {
  var indents = [];
  for (var i = 0; i < nRows; i++) {
    indents.push(
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={130}
        animation="wave"
        style={{ marginBottom: 6 }}
      />
    );
  }

  return indents;
};

export const SkeletonFormComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        height: "500px",
        width: "100%",
      }}
    >
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"100%"}
        animation="wave"
      />
    </div>
  );
};

export const SkeletonSideBarComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        minHeight: "inherit",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "270px",
          height: "100%",
          alignItems: "center",
          boxSizing: "border-box",
          padding: "20px",
          gap: "15px",
          backgroundColor: "rgb(131, 131, 131)",
        }}
      >
        <Skeleton
          variant="circular"
          width={"100%"}
          animation="wave"
          style={{ maxWidth: "100px" }}
          height={100}
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"80px"}
          animation="wave"
        />
      </div>
    </div>
  );
};
