import React, { useState } from "react";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { TextInput, SelectInput } from "./inputs";
import { CustomIconButton } from "./buttons";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SeatsPopUp from "./seatsPopUp";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const TicketsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 30px;
  gap: 30px;
  align-items: center;
  box-sizing: border-box;
`;

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-input": {
            "&.Mui-disabled": {
              "-webkit-text-fill-color": "black",
            },
          },
          "&.Mui-disabled": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "black",
          },
        },
      },
    },
  },
});

const TicketEdit = ({ data, officeOptions, paymentOptions, seats }) => {
  const now = dayjs();
  const dateTime = dayjs(
    `${now.format("YYYY-MM-DD")} ${data.time}`,
    "YYYY-MM-DD HH:mm:ss"
  );
  const navigate = useNavigate();
  const [editable, setEditable] = useState(true);
  const [seen, setSeen] = useState(false);
  const firstOption = officeOptions.find(
    (row) => row.label === data.office_name
  );

  const firstOptionPayment = paymentOptions.find(
    (row) => row.label === data.payment_type
  );

  const initialInputsState = {
    id: data.id,
    name: data.name,
    last_names: data.last_names,
    origin: data.origin,
    destiny: data.destiny,
    phone_number: data.phone_number,
    office: firstOption,
    payment: firstOptionPayment,
    total_amount: data.total_amount,
    amount_debt: data.amount_debt,
    amount_paid: data.amount_paid,
    total_price: data.total_price,
    seats: data.seats,
    external_seller: true,
    office_departure: data.office_departure,
  };
  const [ticketInfo, setTicketInfo] = useState(initialInputsState);
  const [ticketTime, setTicketTime] = useState(dateTime);

  const handleEditButton = () => {
    setEditable(false);
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setTicketInfo((prevInfo) => {
      const newInfo = { ...prevInfo, [field]: value };
      return newInfo;
    });
  };

  const handleSelectChange = (field) => {
    return (e) => {
      setTicketInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleUpdateTicketButtonUpdate = () => {
    const updateValues = {};
    Object.keys(initialInputsState).forEach((key) => {
      let val = ticketInfo[key];
      if (key === "office") {
        val = val.value;
      }
      if (val !== initialInputsState[key]) {
        updateValues[key] = val;
      }
    });
    axiosInstance
      .patch(`/tickets/${ticketInfo.id}/`, updateValues)
      .then((response) => {
        Swal.fire({
          title: "Boleto actualizado",
          text: `La información del boleto de ${response.data.name} ha sido actualizada correctamente.`,
          icon: "success",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
          willClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  function togglePop() {
    setSeen(!seen);
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <Container>
          <h2 style={{ marginBottom: "0px" }}>Informacion del boleto</h2>
          <TicketsContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "20px",
                maxWidth: "240px",
                width: "100%",
              }}
            >
              <TextInput
                label={"Nombre"}
                onChange={handleInputChange("name")}
                value={ticketInfo.name}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={editable}
              />

              <TextInput
                label={"Teléfono"}
                onChange={handleInputChange("phone_number")}
                value={ticketInfo.phone_number}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={editable}
              />
              <TextInput
                label={"Apellido"}
                onChange={handleInputChange("last_names")}
                value={ticketInfo.last_names}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={editable}
              />
              <TextInput
                label={"Punto de abordaje"}
                value={ticketInfo.office_departure}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={editable}
                onChange={handleInputChange("office_departure")}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora de salida"
                  className="timePicker"
                  ampm={false}
                  id="tripTime"
                  value={ticketTime}
                  onChange={(e) => setTicketTime(e)}
                  disabled={editable}
                  sx={{
                    backgroundColor: editable ? "#E0E0E0" : "white",
                  }}
                />
              </LocalizationProvider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "250px",
                  width: "100%",
                }}
              >
                <label>Oficina de registro</label>
                <SelectInput
                  val={ticketInfo.office}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                  options={officeOptions}
                  disabled={editable}
                  onChange={handleSelectChange("office")}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                flexWrap: "wrap",
                maxWidth: "240px",
                width: "100%",
              }}
            >
              <TextInput
                label={"Asiento/s asignado/s"}
                value={data.seats.map((seat) => seat).join(", ")}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={true}
              />
              <TextInput
                label={"Cantidad pagada"}
                onChange={handleInputChange("amount_paid")}
                value={ticketInfo.amount_paid}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={true}
              />
              <TextInput
                label={"Precio por boleto"}
                onChange={handleInputChange("total_price")}
                value={ticketInfo.total_price}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={true}
              />
              <TextInput
                label={"Precio Total"}
                onChange={handleInputChange("total_amount")}
                value={ticketInfo.total_amount}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={true}
              />
              <TextInput
                label={"Debe"}
                disabled={true}
                value={ticketInfo.amount_debt}
                maxWidth={"250px"}
                maxHeight={"56px"}
              />
            </div>
          </TicketsContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              text={"Editar"}
              sx={{ display: editable ? "inherit" : "none" }}
              icon={<EditIcon />}
              onClick={handleEditButton}
            />
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              text={"Guardar"}
              sx={{ display: editable === false ? "inherit" : "none" }}
              icon={<SaveIcon />}
              onClick={handleUpdateTicketButtonUpdate}
            />
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              text={"Editar asientos"}
              sx={{ display: editable === false ? "inherit" : "none" }}
              icon={<SyncAltIcon />}
              onClick={togglePop}
            />
          </div>
          {seen ? (
            <SeatsPopUp
              toggle={togglePop}
              seatsData={seats}
              seatsNumbers={ticketInfo.seats}
              ticketID={ticketInfo.id}
            />
          ) : null}
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default TicketEdit;
