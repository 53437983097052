import styled from "styled-components";
import busfront from "../images/bus-front.png";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import busback from "../images/bus-back.png";
import React, { useState, useEffect, useRef } from "react";
import { SelectInputAsync, SelectInput } from "../components/inputs";
import { State, City } from "country-state-city";
import { CreateButton } from "./buttons";
import axiosInstance from "../interceptors/axiosInstance";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import LoadingScreen from "../components/loadingScreen";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { InputAdornment, useMediaQuery, Tabs, Tab, Box } from "@mui/material";
import { TextInput } from "./inputs";
import Slide from "@mui/material/Slide";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PrintTicketPopUp from "../windows/printTicketPopUp";

const Container = React.forwardRef((props, ref) => (
  <StyledContainer ref={ref} {...props} />
));

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.3);
`;

const MobileContainer = React.forwardRef((props, ref) => (
  <StyledMobileContainer ref={ref} {...props} />
));

const StyledMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 20px;
`;

const SeatPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  width: fit-content;
`;

const Images = styled.img`
  max-width: 280px;
`;

const BusSeatsRow = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 0px 8px;
  gap: 3px;
`;

const BusSeat = styled.div.attrs((props) => ({
  "data-islastrow": props.islastrow,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${(props) =>
    props.isEmpty ? (props.isSelected ? "white" : "inherit") : "grey"};
  pointer-events: ${(props) => (props.isEmpty ? "auto" : "none")};
  background-color: ${(props) =>
    props.isTicketSeat ? "#19b78f" : props.isSelected ? "#1990b7" : ""};
  padding: ${(props) => (props.islastrow ? "0px 18px" : "0px 18px")};
  &:hover {
    color: ${(props) => (props.isSelected ? "" : "#1990b7")};
    cursor: pointer;
  }
`;

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const EmptySeat = styled.div`
  width: 20px;
  margin: 5px;
`;

const SeatSelectorComponent = ({ seatsData, busInfo }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const slideRef = useRef(null);
  const initialInputsState = {
    name: "",
    last_names: "",
    total_amount: "",
    amount_paid: 0,
    amount_debt: "",
    payment: "",
    office: "",
    origin: "",
    destiny: "",
    phone_number: "",
    amount_per_ticket: 0,
    external_seller: false,
    ticket_time: null,
    office_departure: "",
  };

  const [ticketResponse, setTicketResponse] = useState(null);

  const [externalSellerName, setExternalSellerName] = useState("");

  const [folio, setFolio] = useState("");

  const [checked, setChecked] = React.useState(false);

  const [customerInfo, setCustomerInfo] = useState(initialInputsState);

  const [selectedSeats, setSelectedSeats] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setCustomerInfo((prevInfo) => {
      const newInfo = { ...prevInfo, [field]: value };
      return newInfo;
    });
  };

  const handleSelectChange = (field) => {
    return (e) => {
      setCustomerInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleSelectExternalChange = (field) => {
    return (e) => {
      setCustomerInfo((prev) => ({
        ...prev,
        [field]: e.value,
      }));
    };
  };

  const seatChunks = chunkArray(seatsData, 4);

  const [origin, setOrigin] = useState({
    statesOptions: [],
    statesCodes: {},
    selectedState: null,
    citiesOptions: [],
  });

  const [destiny, setDestiny] = useState({
    statesOptions: [],
    statesCodes: {},
    selectedState: null,
    citiesOptions: [],
  });

  const handleOriginStateChange = (newState) => {
    setOrigin((prev) => ({
      ...prev,
      selectedState: newState,
    }));
  };

  const handleDestinyStateChange = (newState) => {
    setDestiny((prev) => ({
      ...prev,
      selectedState: newState,
    }));
  };

  useEffect(() => {
    setChecked(true);
    const stateValues = State.getStatesOfCountry("MX");
    const stateList = stateValues.map((city) => ({
      value: city.name,
      label: city.name,
    }));

    const stateCodesList = stateValues.reduce((acc, state) => {
      acc[state.name] = state.isoCode;
      return acc;
    }, {});

    setOrigin((prev) => ({
      ...prev,
      statesOptions: stateList,
      statesCodes: stateCodesList,
    }));

    setDestiny((prev) => ({
      ...prev,
      statesOptions: stateList,
      statesCodes: stateCodesList,
    }));
  }, []);

  useEffect(() => {
    if (origin.selectedState && origin.selectedState.label) {
      const availableCities = City.getCitiesOfState(
        "MX",
        origin.statesCodes[origin.selectedState["label"]]
      );

      const citiesList = availableCities.map((city) => ({
        value: city.name,
        label: city.name,
      }));

      setOrigin((prev) => ({
        ...prev,
        citiesOptions: citiesList,
      }));
    }
  }, [origin.selectedState, origin.statesCodes]);

  useEffect(() => {
    if (destiny.selectedState && destiny.selectedState.label) {
      const availableCities = City.getCitiesOfState(
        "MX",
        destiny.statesCodes[destiny.selectedState["label"]]
      );

      const citiesList = availableCities.map((city) => ({
        value: city.name,
        label: city.name,
      }));

      setDestiny((prev) => ({
        ...prev,
        citiesOptions: citiesList,
      }));
    }
  }, [destiny.selectedState, destiny.statesCodes]);

  useEffect(() => {
    const total = (
      parseFloat(customerInfo.amount_per_ticket || 0) * selectedSeats.length
    ).toFixed(2);
    const debt = (total - parseFloat(customerInfo.amount_paid || 0)).toFixed(2);

    setCustomerInfo((prevState) => ({
      ...prevState,
      total_amount: total,
      amount_debt: debt,
    }));
  }, [selectedSeats, customerInfo.amount_per_ticket, customerInfo.amount_paid]);

  useEffect(() => {
    if (checked && slideRef.current) {
      slideRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [checked]);

  const isFormValid = () => {
    if (customerInfo.external_seller === true) {
      const isValid =
        customerInfo.amount_per_ticket !== 0 &&
        customerInfo.amount_paid !== "" &&
        customerInfo.origin !== "" &&
        customerInfo.destiny !== "" &&
        externalSellerName !== "" &&
        selectedSeats.length !== 0 &&
        selectedSeats.office !== "";

      return isValid;
    } else if (
      customerInfo.amount_paid === 0 ||
      customerInfo.amount_paid === "0"
    ) {
      const isValid =
        Object.values(customerInfo).every((value, index) => {
          const keys = Object.keys(customerInfo);
          const key = keys[index];

          if (
            key === "office_departure" ||
            key === "amount_paid" ||
            key === "folio" ||
            key === "payment"
          ) {
            return true;
          }

          return value !== "";
        }) && selectedSeats.length !== 0;

      return isValid;
    } else {
      const folioRequired =
        customerInfo.payment.label !== "Dinero en efectivo"
          ? folio !== ""
            ? true
            : false
          : true;

      const isValid =
        Object.values(customerInfo).every((value, index) => {
          const keys = Object.keys(customerInfo);
          const key = keys[index];

          if (key === "office_departure") {
            return true;
          }
          return value !== "";
        }) &&
        selectedSeats.length !== 0 &&
        folioRequired;

      return isValid;
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    if (
      !/^[0-9]*$/.test(key) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      key !== "ArrowLeft" &&
      key !== "ArrowRight" &&
      key !== "Tab"
    ) {
      event.preventDefault();
    }
  };

  const handleSeatClick = (seat_id) => {
    let id = seat_id.id;
    setSelectedSeats((prevSelectedSeats) => {
      if (prevSelectedSeats.includes(id)) {
        return prevSelectedSeats.filter((seatId) => seatId !== id);
      } else {
        return [...prevSelectedSeats, id];
      }
    });
  };

  const loadOfficeOptions = () => {
    return axiosInstance
      .get(`/offices/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((office) => ({
          label: office.name,
          value: office.id,
        }));
        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const loadPaymentOptions = () => {
    return axiosInstance
      .get(`/payment/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const options = response.data.map((row) => ({
          label: row.name,
          value: row.id,
        }));
        return options;
      })
      .catch((error) => {
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleInputTimeChange = (field) => {
    return (e) => {
      setCustomerInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const handleCreateTicketClick = (e) => {
    setIsLoading(true);

    if (parseFloat(customerInfo.amount_paid) > customerInfo.total_amount) {
      Swal.fire({
        title: "Error",
        text: "El pago no puede ser mayor a la cantidad total.",
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
      setIsLoading(false);
    } else {
      e.preventDefault();
      try {
        const currentDate = new Date();

        const request_data = {
          amount_debt: customerInfo.amount_debt,
          amount_paid: customerInfo.amount_paid,
          name: capitalizeWords(customerInfo.name),
          last_names: capitalizeWords(customerInfo.last_names),
          origin: customerInfo.origin.value,
          destiny: customerInfo.destiny.value,
          trip_date: busInfo.date,
          time: customerInfo.ticket_time.format("HH:mm"),
          phone_number: customerInfo.phone_number,
          total_amount: customerInfo.total_amount,
          is_ticket_paid: parseFloat(customerInfo.amount_debt) === 0.0,
          office: customerInfo.office.value,
          bus: busInfo.id,
          external_seller: customerInfo.external_seller,
          external_seller_name: capitalizeWords(externalSellerName),
          total_price: customerInfo.amount_per_ticket,
          user_id: localStorage.getItem("user_id"),
          office_departure:
            customerInfo.office_departure !== ""
              ? customerInfo.office_departure
              : customerInfo.office.label,
          seats: selectedSeats,
          payment: customerInfo.payment.value,
          payment_date: currentDate,
          folio: folio,
        };
        axiosInstance
          .post("/tickets/", request_data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            setTicketResponse(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code === "TOKEN_EXP") {
              Swal.fire({
                title: "Error",
                text: "Sesión cerrada por seguridad, inicie nuevamente",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
              localStorage.clear();
              navigate("/");
            } else {
              Swal.fire({
                title: "Error",
                text:
                  "Ha ocurrido un error inesperado, compartir el error con el admin(" +
                  error.message +
                  ")",
                icon: "error",
                iconColor: "#1ccda0",
                confirmButtonColor: "#0da290",
              });
            }
          });
      } catch (error) {
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          text:
            "Ha ocurrido un error inesperado, compartir el error con el admin(" +
            error.message +
            ")",
          icon: "error",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
        });
      }
    }
  };

  function capitalizeWords(string) {
    if (string === "") {
      return string;
    }
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const verifyAmountPaid = () => {
    return (
      customerInfo.amount_paid === 0 ||
      customerInfo.amount_paid === "" ||
      customerInfo.amount_paid === "0"
    );
  };

  return isLoading ? (
    <LoadingScreen />
  ) : !isMobile ? (
    <Slide direction="up" in={checked}>
      <Container ref={slideRef}>
        {ticketResponse !== null ? (
          <PrintTicketPopUp ticketData={ticketResponse} reloadWindow={true} />
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <h1>Selección de asientos</h1>
          <SeatPickerContainer>
            <Images src={busfront} alt="front bus" />
            {seatChunks.map((chunk, rowIndex) => (
              <BusSeatsRow
                key={rowIndex}
                islastrow={rowIndex === seatChunks.length - 1}
              >
                {chunk.map((seat, index) => (
                  <React.Fragment key={seat.id}>
                    <BusSeat
                      isEmpty={seat.is_empty}
                      isSelected={selectedSeats.includes(seat.id)}
                      onClick={() => handleSeatClick(seat)}
                      islastrow={rowIndex === seatChunks.length - 1}
                    >
                      <EventSeatIcon />
                      <p style={{ margin: "0px" }}>{seat.seat_number}</p>
                    </BusSeat>
                    {index % 2 === 1 && index !== chunk.length - 1 && (
                      <EmptySeat />
                    )}
                  </React.Fragment>
                ))}
              </BusSeatsRow>
            ))}
            <Images src={busback} alt="back bus" />
          </SeatPickerContainer>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1>Informacion del pasajero</h1>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "start",
              gap: "30px",
              height: "100%",
            }}
          >
            <TextInput
              label={"Nombre"}
              onChange={handleInputChange("name")}
              value={customerInfo.name}
              maxWidth={"250px"}
              maxHeight={"56px"}
            />
            <TextInput
              label={"Apellidos"}
              onChange={handleInputChange("last_names")}
              value={customerInfo.last_names}
              maxWidth={"250px"}
              maxHeight={"56px"}
            />
            <TextInput
              label="Precio por boleto"
              onChange={handleInputChange("amount_per_ticket")}
              onKeyDown={handleKeyDown}
              value={customerInfo.amount_per_ticket}
              maxWidth={"250px"}
              maxHeight={"56px"}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              label="Cantidad abonada"
              onChange={handleInputChange("amount_paid")}
              onKeyDown={handleKeyDown}
              value={customerInfo.amount_paid}
              maxWidth={"250px"}
              maxHeight={"56px"}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled={true}
              label="Precio total"
              value={customerInfo.total_amount}
              onChange={handleInputChange("total_amount")}
              maxWidth={"250px"}
              maxHeight={"56px"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled={true}
              label="Cantidad a deber"
              value={customerInfo.amount_debt}
              onChange={handleInputChange("amount_debt")}
              maxWidth={"250px"}
              maxHeight={"56px"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <div className="selectsContainer">
              <label>Tipo de pago</label>
              <div className="selectFields">
                <SelectInputAsync
                  placeholder={"Seleccione uno"}
                  val={customerInfo.payment}
                  onchange={handleSelectChange("payment")}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                  options={loadPaymentOptions}
                  disabled={verifyAmountPaid()}
                />
                <TextInput
                  label={"Clave de rastreo"}
                  onChange={(e) => setFolio(e.target.value)}
                  value={folio}
                  maxWidth="250px"
                  maxHeight="56px"
                  disabled={
                    customerInfo.payment.label === "Dinero en efectivo" ||
                    customerInfo.payment === ""
                  }
                />
              </div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Hora de salida"
                className="timePicker"
                id="ticket_time"
                value={customerInfo.ticket_time}
                onChange={handleInputTimeChange("ticket_time")}
                sx={{
                  backgroundColor: "white",
                  maxWidth: "250px",
                  width: "100%",
                }}
              />
            </LocalizationProvider>
            <PhoneInput
              name="phoneNumber"
              defaultCountry="MX"
              placeholder="Número de Teléfono"
              className="classPhone"
              onChange={handleSelectChange("phone_number")}
              value={customerInfo.phone_number}
            />
            <div className="selectsContainer">
              <label>Origen</label>
              <div className="selectFields">
                <SelectInput
                  placeholder={"Seleccione estado"}
                  options={origin.statesOptions}
                  onChange={(e) => handleOriginStateChange(e)}
                  val={origin.selectedState}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
                <SelectInput
                  placeholder={"Seleccione ciudad"}
                  options={origin.citiesOptions}
                  onChange={handleSelectChange("origin")}
                  val={customerInfo.origin}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
              </div>
            </div>
            <div className="selectsContainer">
              <label>Destino</label>
              <div className="selectFields">
                <SelectInput
                  placeholder={"Seleccione estado"}
                  options={destiny.statesOptions}
                  onChange={handleDestinyStateChange}
                  val={destiny.selectedState}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
                <SelectInput
                  placeholder={"Seleccione ciudad"}
                  options={destiny.citiesOptions}
                  onChange={handleSelectChange("destiny")}
                  val={customerInfo.destiny}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
              </div>
            </div>
            <div className="selectsContainerRow">
              <div className="selectFieldsRow">
                <label>Oficina</label>
                <SelectInputAsync
                  placeholder={"Oficina de registro"}
                  val={customerInfo.office}
                  onchange={handleSelectChange("office")}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                  options={loadOfficeOptions}
                />
              </div>
              <div className="selectFieldsRow">
                <h5 style={{ margin: 2 }}>
                  *Si no es en la oficina de registro
                </h5>
                <TextInput
                  label={"Lugar de abordaje"}
                  onChange={handleInputChange("office_departure")}
                  value={customerInfo.office_departure}
                  maxWidth="250px"
                  maxHeight="56px"
                />
              </div>
            </div>
            <div className="selectsContainer">
              <label>¿Vendedor externo?</label>
              <div className="selectFields">
                <SelectInput
                  placeholder={"Seleccione uno"}
                  options={[
                    { value: true, label: "Si" },
                    { value: false, label: "No" },
                  ]}
                  onChange={handleSelectExternalChange("external_seller")}
                  defaultValue={1}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
                <TextInput
                  disabled={!customerInfo.external_seller}
                  label={"Nombre vendedor externo"}
                  value={externalSellerName}
                  onChange={(e) => setExternalSellerName(e.target.value)}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "530px",
                justifyContent: "center",
                height: "90px",
              }}
            >
              <CreateButton
                onClick={handleCreateTicketClick}
                maxWidth={"250px"}
                maxHeight={"56px"}
                disabled={!isFormValid()}
                text={"Crear boleto"}
              />
            </div>
          </div>
        </div>
      </Container>
    </Slide>
  ) : (
    <Slide style={{ width: "100%" }} direction="up" in={checked}>
      <MobileContainer ref={slideRef}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          orientation="vertical"
        >
          <Tab label="Selección de asientos" />
          <Tab label="Información del pasajero" />
        </Tabs>
        {tabIndex === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SeatPickerContainer>
              <Images src={busfront} alt="front bus" />
              {seatChunks.map((chunk, rowIndex) => (
                <BusSeatsRow
                  key={rowIndex}
                  islastrow={rowIndex === seatChunks.length - 1}
                >
                  {chunk.map((seat, index) => (
                    <React.Fragment key={seat.id}>
                      <BusSeat
                        isEmpty={seat.is_empty}
                        isSelected={selectedSeats.includes(seat.id)}
                        onClick={() => handleSeatClick(seat)}
                        islastrow={rowIndex === seatChunks.length - 1}
                      >
                        <EventSeatIcon />
                        <p style={{ margin: "0px" }}>{seat.seat_number}</p>
                      </BusSeat>
                      {index % 2 === 1 && index !== chunk.length - 1 && (
                        <EmptySeat />
                      )}
                    </React.Fragment>
                  ))}
                </BusSeatsRow>
              ))}
              <Images src={busback} alt="back bus" />
            </SeatPickerContainer>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <TextInput
              label="Nombre"
              onChange={handleInputChange("name")}
              value={customerInfo.name}
              maxWidth="250px"
              maxHeight="56px"
            />
            <TextInput
              label="Apellidos"
              onChange={handleInputChange("last_names")}
              value={customerInfo.last_names}
              maxWidth="250px"
              maxHeight="56px"
            />
            <TextInput
              label="Precio por boleto"
              onChange={handleInputChange("amount_per_ticket")}
              onKeyDown={handleKeyDown}
              value={customerInfo.amount_per_ticket}
              maxWidth="250px"
              maxHeight="56px"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              label="Cantidad abonada"
              onChange={handleInputChange("amount_paid")}
              onKeyDown={handleKeyDown}
              value={customerInfo.amount_paid}
              maxWidth="250px"
              maxHeight="56px"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled
              label="Precio total"
              value={customerInfo.total_amount}
              onChange={handleInputChange("total_amount")}
              maxWidth="250px"
              maxHeight="56px"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextInput
              disabled
              label="Cantidad a deber"
              value={customerInfo.amount_debt}
              onChange={handleInputChange("amount_debt")}
              maxWidth="250px"
              maxHeight="56px"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <div className="selectsContainer">
              <label>Tipo de pago</label>
              <div className="selectFields">
                <SelectInputAsync
                  placeholder={"Seleccione uno"}
                  val={customerInfo.payment}
                  onchange={handleSelectChange("payment")}
                  maxWidth={"250px"}
                  maxHeight={"56px"}
                  options={loadPaymentOptions}
                  disabled={verifyAmountPaid()}
                />
                <TextInput
                  label={"Clave de rastreo"}
                  onChange={(e) => setFolio(e.target.value)}
                  value={folio}
                  maxWidth="250px"
                  maxHeight="56px"
                  disabled={
                    customerInfo.payment.label === "Dinero en efectivo" ||
                    customerInfo.payment === ""
                  }
                />
              </div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Hora de salida"
                className="timePicker"
                id="ticket_time"
                value={customerInfo.ticket_time}
                onChange={(newValue) =>
                  handleInputTimeChange("ticket_time")(newValue)
                }
                sx={{ backgroundColor: "white" }}
              />
            </LocalizationProvider>
            <PhoneInput
              name="phoneNumber"
              defaultCountry="MX"
              placeholder="Número de Teléfono"
              className="classPhone"
              onChange={handleSelectChange("phone_number")}
              value={customerInfo.phone_number}
            />
            <div className="selectsContainerMobile">
              <label>Origen</label>
              <SelectInput
                placeholder="Seleccione estado"
                options={origin.statesOptions}
                onChange={handleOriginStateChange}
                val={origin.selectedState}
                maxWidth="250px"
                maxHeight="56px"
              />
              <SelectInput
                placeholder="Seleccione ciudad"
                options={origin.citiesOptions}
                onChange={handleSelectChange("origin")}
                val={customerInfo.origin}
                maxWidth="250px"
                maxHeight="56px"
              />
            </div>
            <div className="selectsContainerMobile">
              <label>Destino</label>
              <SelectInput
                placeholder="Seleccione estado"
                options={destiny.statesOptions}
                onChange={handleDestinyStateChange}
                val={destiny.selectedState}
                maxWidth="250px"
                maxHeight="56px"
              />
              <SelectInput
                placeholder="Seleccione ciudad"
                options={destiny.citiesOptions}
                onChange={handleSelectChange("destiny")}
                val={customerInfo.destiny}
                maxWidth="250px"
                maxHeight="56px"
              />
            </div>
            <div className="selectsContainerMobile">
              <label>Oficina</label>
              <SelectInputAsync
                placeholder="Oficina de registro"
                val={customerInfo.office}
                onchange={handleSelectChange("office")}
                maxWidth="250px"
                maxHeight="56px"
                options={loadOfficeOptions}
              />
              <h5 style={{ margin: 2 }}>*Si no es en la oficina de registro</h5>
              <TextInput
                label={"Lugar de abordaje"}
                onChange={handleInputChange("office_departure")}
                value={customerInfo.office_departure}
                maxWidth="250px"
                maxHeight="56px"
              />
            </div>
            <div className="selectsContainerMobile">
              <label>¿Vendedor externo?</label>
              <SelectInput
                placeholder="Seleccione uno"
                options={[
                  { value: false, label: "Si" },
                  { value: true, label: "No" },
                ]}
                onChange={handleSelectExternalChange("external_seller")}
                defaultValue={1}
                maxWidth="250px"
                maxHeight="56px"
              />
              <TextInput
                disabled={!customerInfo.external_seller}
                label="Nombre vendedor externo"
                value={externalSellerName}
                onChange={(e) => setExternalSellerName(e.target.value)}
                maxWidth="250px"
                maxHeight="56px"
              />
            </div>
            <Box
              sx={{
                display: "flex",
                width: "530px",
                justifyContent: "center",
                height: "90px",
              }}
            >
              <CreateButton
                text={"Crear Boleto"}
                onClick={handleCreateTicketClick}
                maxWidth="250px"
                maxHeight="56px"
                disabled={!isFormValid()}
              />
            </Box>
          </Box>
        )}
        {ticketResponse !== null ? (
          <PrintTicketPopUp ticketData={ticketResponse} />
        ) : null}
      </MobileContainer>
    </Slide>
  );
};

export default SeatSelectorComponent;
