import React, { useState } from "react";
import "./administrationPanel";
import CreateUser from "./createUser";
import DeleteUser from "./deleteUser";
import { CSSTransition } from "react-transition-group";

const EditUsers = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  return (
    <div className="mainContainer">
      <h3
        onClick={() => setShowCreate(!showCreate)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showCreate ? "0px" : "20px",
        }}
      >
        Crear usuario {showCreate ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showCreate}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <CreateUser />
        </div>
      </CSSTransition>
      <h3
        onClick={() => setShowDelete(!showDelete)}
        style={{
          margin: "0",
          cursor: "pointer",
          marginBottom: showDelete ? "0px" : "20px",
        }}
      >
        Eliminar usuario {showDelete ? "-" : "+"}
      </h3>
      <CSSTransition
        in={showDelete}
        timeout={300}
        classNames="card"
        unmountOnExit
      >
        <div className="cardContainer">
          <DeleteUser />
        </div>
      </CSSTransition>
    </div>
  );
};

export default EditUsers;
