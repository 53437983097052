import React, { useState } from "react";
import "./generateReports.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DepartureReport from "./departureReport";
import MoneyReport from "./moneyReport";

const GenerateReports = () => {
  const [value, setValue] = useState("1");
  const [selectedComponent, setSelectedComponent] = useState(
    <DepartureReport />
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case "1":
        setSelectedComponent(<DepartureReport />);
        break;
      case "2":
        setSelectedComponent(<MoneyReport />);
        break;
      case "3":
        break;
      default:
        break;
    }
  };
  return localStorage.getItem("is_superuser") === "true" ? (
    <div className="generateReportsContainer">
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        variant="fullWidth"
        style={{ marginBottom: "20px" }}
      >
        <Tab value={"1"} label="Reporte de salida" />
        <Tab value={"2"} label="Reporte Monetario" />
        <Tab value={"3"} label="Reporte de Ventas" />
      </Tabs>
      {selectedComponent && (
        <div className="reportsContentContainer">{selectedComponent}</div>
      )}
    </div>
  ) : (
    <div className="generateReportsContainer">
      <h2 style={{ textAlign: "center" }}>Reporte de salida</h2>
      <div className="reportsContentContainer">
        <DepartureReport />
      </div>
    </div>
  );
};

export default GenerateReports;
