import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";

export async function GetAllBusses(
  endpoint,
  successCallback,
  loadingFunction,
  navigateFunction,
  params = null,
  config = null
) {
  try {
    successCallback(null);
    loadingFunction(true);
    const response = await axiosInstance.get(endpoint, params, config);
    if (response.status === 204) {
      Swal.fire({
        title: "Advertencia",
        text: "No se encontraron salidas disponibles, contacte al administrador",
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
    } else {
      response.data = response.data.sort((a, b) => {
        const dateA = new Date(a.date.split("-").reverse().join("-"));
        const dateB = new Date(b.date.split("-").reverse().join("-"));

        return dateA - dateB;
      });
      successCallback(response);
    }
  } catch (err) {
    if (err.code === "TOKEN_EXP") {
      Swal.fire({
        title: "Error",
        text: "Sesión cerrada por seguridad, inicie nuevamente",
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
      localStorage.clear();
      navigateFunction("/");
    } else {
      if (err.code === "ERR_BAD_REQUEST") {
        if (err.response.data.code === "token_not_valid") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigateFunction("/");
        }
      } else {
        Swal.fire({
          title: "Error",
          text: err.message
            ? err.message
            : "Error inesperado, por favor intente nuevamente.",
          icon: "error",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
        });
      }
    }
  } finally {
    loadingFunction(false);
  }
}
