import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import "./components.css";
import Paper from "@mui/material/Paper";

const CustomTable = styled(Table)`
  table-layout: fixed;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.3);
`;

const CustomCell = styled(TableCell)`
  font-family: "Poppins", sans-serif;
  padding: 0px;
  height: 40px;
  border-bottom: black solid 1px;
`;

const CustomHead = styled(TableHead)`
  .MuiTableCell-root {
    background-color: black;
    color: white;
  }
`;

const CustomBody = styled(TableBody)`
  background-color: white;
  .MuiTableRow-root {
    &:hover {
      background-color: #1990b7 !important;
      cursor: pointer;
    }
  }
`;

const ScrollContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400,
  overflow: "auto",
}));

const TableComponent = ({ dataInfo, clickHandle, headers, cellName }) => {
  const [clickedRowId, setClickedRowId] = useState(null);
  const handleRowClick = (row) => {
    setClickedRowId(row.id);
    clickHandle(row);
  };

  const formatDate = (fecha) => {
    const [day, month, year] = fecha.split("-");
    const fechaObjeto = new Date(year, month - 1, day);
    const opcionesDia = { weekday: "long" };
    const opcionesMes = { month: "long" };

    let nombreDelDia = fechaObjeto.toLocaleDateString("es-ES", opcionesDia);
    let nombreDelMes = fechaObjeto.toLocaleDateString("es-ES", opcionesMes);

    nombreDelDia = nombreDelDia.charAt(0).toUpperCase() + nombreDelDia.slice(1);
    nombreDelMes = nombreDelMes.charAt(0).toUpperCase() + nombreDelMes.slice(1);

    return `${nombreDelDia} ${day} de ${nombreDelMes} de ${year}`;
  };

  return (
    <ScrollContainer component={Paper}>
      <CustomTable stickyHeader>
        <CustomHead>
          <TableRow>
            {headers.map((header) => (
              <CustomCell key={header} align="center">
                {header}
              </CustomCell>
            ))}
          </TableRow>
        </CustomHead>
        <CustomBody>
          {dataInfo.data.map((row) => (
            <TableRow
              selected
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor:
                  clickedRowId === row.id ? "#1990b7 !important" : "",
              }}
              onClick={() => handleRowClick(row)}
              hover={true}
            >
              {cellName.map((cell) =>
                cell === `date` ? (
                  <CustomCell key={cell} align="center">
                    {formatDate(row[cell])}
                  </CustomCell>
                ) : (
                  <CustomCell key={cell} align="center">
                    {row[cell]}
                  </CustomCell>
                )
              )}
            </TableRow>
          ))}
        </CustomBody>
      </CustomTable>
    </ScrollContainer>
  );
};

export default TableComponent;
