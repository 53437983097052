import React, { useState } from "react";
import { TextInput, SelectInput } from "../components/inputs";
import "./createUser.css";
import { CreateButton } from "../components/buttons";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";

function CreateUser() {
  const initialState = {
    username: "",
    password: "",
    repeat_password: "",
    is_super_user: { label: "No", value: "False" },
  };
  const [userInfo, setUserInfo] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (field) => {
    return (e) => {
      setUserInfo((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };
  };

  const handleSelectChange = (field) => {
    return (e) => {
      setUserInfo((prev) => ({
        ...prev,
        [field]: e,
      }));
    };
  };

  const isFormValid = () => {
    return (
      Object.values(userInfo).every((value) => value !== "") &&
      userInfo.password === userInfo.repeat_password
    );
  };

  const handleCreateUser = () => {
    if (userInfo.password !== userInfo.repeat_password) {
      Swal.fire({
        title: "Error",
        text: "Las contraseñas no coinciden",
        icon: "error",
        iconColor: "#1ccda0",
        confirmButtonColor: "#0da290",
      });
      return;
    }

    setIsLoading(true);
    axiosInstance
      .post(
        `/users/`,
        {
          username: userInfo.username,
          password: userInfo.password,
          is_super_user: userInfo.is_super_user.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        Swal.fire({
          title: "Usuario creado",
          text: `El usuario ${response.data["Created user"]["username"]} se ha creado exitosamente.`,
          icon: "success",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
          willClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          localStorage.clear();
          navigate("/");
        }
      });
  };

  return (
    <div className="createUserContainer">
      <div className="createUserRows">
        <TextInput
          label={"Nombre del usuario"}
          onChange={handleInputChange("username")}
          value={userInfo.username}
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
        <TextInput
          label={"Contraseña"}
          onChange={handleInputChange("password")}
          value={userInfo.password}
          type="password"
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
        <TextInput
          label={"Repetir la contraseña"}
          onChange={handleInputChange("repeat_password")}
          value={userInfo.repeat_password}
          type="password"
          maxWidth={"250px"}
          maxHeight={"56px"}
          minWidth={"199px"}
        />
      </div>
      <div className="createUserRows">
        <label style={{ paddingLeft: "10px" }}>
          ¿Desea que el usuario sea administrador?
        </label>
        <SelectInput
          placeholder={"Seleccione uno"}
          options={[
            { label: "Si", value: "True" },
            { label: "No", value: "False" },
          ]}
          onChange={handleSelectChange("is_super_user")}
          val={userInfo.is_super_user}
          maxWidth={"250px"}
          maxHeight={"56px"}
        />
      </div>
      <div className="createUserRows">
        <CreateButton
          onClick={() => handleCreateUser()}
          maxWidth={"250px"}
          maxHeight={"56px"}
          text={"Crear usuario"}
          disabled={!isFormValid()}
        />
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
}

export default CreateUser;
