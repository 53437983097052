import React, { useEffect, useState, useRef } from "react";
import { GetAllBusses } from "../api/apiFunctions";
import { useNavigate } from "react-router-dom";
import { TableSkeletonComponent } from "../components/skeletonComponents";
import TableComponent from "../components/tableComponent";
import axiosInstance from "../interceptors/axiosInstance";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { useReactToPrint } from "react-to-print";
import "./moneyReport.css";
import { CustomIconButton } from "../components/buttons";
import PrintIcon from "@mui/icons-material/Print";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

const CustomCell = styled(TableCell)`
  font-family: "Poppins", sans-serif;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  @media print {
    font-size: 8px;
  }
`;

const NoPrintCell = styled(TableCell)`
  font-family: "Poppins", sans-serif;
  padding: 0px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media print {
    display: none;
  }
`;

const CustomHead = styled(TableHead)`
  .MuiTableCell-root {
    background-color: black;
    color: white;

    @media print {
      background-color: white;
      color: black;
    }
  }
`;

const ScrollableContainer = styled("div")`
  overflow-x: auto;
  width: 100%;
`;

const MoneyReport = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [seatsInformation, setSeatsInformation] = useState(null);
  const [data, setData] = useState(null);
  const [paymentsData, setPaymentsData] = useState(null);
  const tableRef = useRef();
  const [openRows, setOpenRows] = React.useState({});
  const handleRowClick = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          many: true,
        },
      }
    );
  }, [navigate]);

  const handleBusClick = (row) => {
    setIsLoadingReport(true);
    axiosInstance
      .get(`/tickets/`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          bus: row.id,
        },
      })
      .then((response) => {
        setIsLoadingReport(false);
        if (response.status === 204) {
          Swal.fire({
            title: "Advertencia",
            text: "No se encontró información del autobus seleccionado.",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        } else {
          const ids = response.data.map((ticket) => ticket.id);
          axiosInstance
            .get("/payments/", {
              headers: {
                "Content-Type": "application/json",
              },
              params: { ticket_ids: ids },
            })
            .then((response) => {
              setPaymentsData(response.data);
            })
            .catch((error) => {
              console.log("Error");
            });
          const groupedByOffice = response.data.reduce((acc, item) => {
            if (!acc[item.office_name]) {
              acc[item.office_name] = [];
            }
            acc[item.office_name].push(item);
            return acc;
          }, {});
          setSeatsInformation(groupedByOffice);
        }
      })
      .catch((error) => {
        setIsLoadingReport(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  function totalAmount(items) {
    return items
      .map((item) => (item.total_amount ? item.total_amount : 0))
      .reduce((sum, i) => sum + i, 0);
  }

  function totalAmountDebt(items) {
    return items
      .map((item) => (item.amount_debt ? item.amount_debt : 0))
      .reduce((sum, i) => sum + i, 0);
  }

  function totalAmountPaid(items) {
    return items
      .map((item) => (item.amount_paid ? item.amount_paid : 0))
      .reduce((sum, i) => sum + i, 0);
  }

  function findByTicketId(ticketId) {
    return paymentsData.filter((payment) => payment.ticket_id === ticketId);
  }

  const formatDate = (fecha) => {
    const [date, hour] = fecha.split(" ");
    const [year, month, day] = date.split("-");
    const fechaObjeto = new Date(year, month - 1, day);
    const opcionesMes = { month: "long" };

    let nombreDelMes = fechaObjeto.toLocaleDateString("es-ES", opcionesMes);

    nombreDelMes = nombreDelMes.charAt(0).toUpperCase() + nombreDelMes.slice(1);

    return `${day} de ${nombreDelMes} de ${year} a las ${hour}`;
  };

  return (
    <>
      {isLoading ? (
        <TableSkeletonComponent nRows={6} />
      ) : (
        data && (
          <TableComponent
            dataInfo={data}
            headers={["Autobus", "Fecha", "Ruta", "Hora"]}
            cellName={["bus_name", "date", "route_name", "time"]}
            clickHandle={handleBusClick}
          />
        )
      )}

      {isLoadingReport ? (
        <TableSkeletonComponent nRows={6} />
      ) : (
        seatsInformation &&
        paymentsData && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
              alignItems: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <CustomIconButton
              maxWidth={"230px"}
              maxHeight={"56px"}
              onClick={() => handlePrint()}
              text={"Imprimir reporte"}
              icon={<PrintIcon />}
            />
            <div className="moneyReportContainer" ref={tableRef}>
              <ScrollableContainer>
                {Object.keys(seatsInformation).map((key) => {
                  let office_data = seatsInformation[key];
                  let cash_total = 0;
                  let transfer_total = 0;
                  return (
                    <TableContainer
                      sx={{
                        width: "100%",
                        marginBottom: "40px",
                      }}
                      component={Paper}
                      key={key}
                    >
                      <Table sx={{ border: "black solid 2px" }}>
                        <CustomHead>
                          <TableRow>
                            <CustomCell width={"9%"} align="center">
                              Nombre
                            </CustomCell>
                            <CustomCell width={"9%"} align="center">
                              Apellidos
                            </CustomCell>
                            <CustomCell align="center">Origen</CustomCell>
                            <CustomCell align="center">Destino</CustomCell>
                            <CustomCell width={"9%"} align="center">
                              Sucursal de registro
                            </CustomCell>
                            <CustomCell width={"9%"} align="center">
                              V. Externo
                            </CustomCell>
                            <CustomCell width={"10%"} align="center">
                              Abonado
                            </CustomCell>
                            <CustomCell width={"10%"} align="center">
                              Por pagar
                            </CustomCell>
                            <CustomCell width={"10%"} align="center">
                              Total a Recaudar
                            </CustomCell>
                          </TableRow>
                        </CustomHead>
                        <TableBody>
                          {office_data.map((row) => {
                            const filteredPayments = findByTicketId(row.id);

                            return (
                              <React.Fragment key={row.id}>
                                <TableRow>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.name}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.last_names}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.origin}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.destiny}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.office_name}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    {row.external_seller_name}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    $ {row.amount_paid}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    $ {row.amount_debt}
                                  </CustomCell>
                                  <CustomCell sx={{ paddingLeft: "5px" }}>
                                    $ {row.total_amount}
                                  </CustomCell>
                                  {filteredPayments.length > 0 ? (
                                    <NoPrintCell>
                                      <IconButton
                                        aria-label="expand row"
                                        onClick={() => handleRowClick(row.id)}
                                        sx={{ height: "10px" }}
                                      >
                                        {openRows[row.id] ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    </NoPrintCell>
                                  ) : null}
                                </TableRow>

                                {filteredPayments.length > 0 ? (
                                  <TableRow>
                                    <CustomCell
                                      style={{
                                        paddingBottom: openRows[row.id]
                                          ? 10
                                          : 0,
                                        borderBottom: openRows[row.id]
                                          ? "solid black"
                                          : "0",
                                      }}
                                      colSpan={5}
                                    >
                                      <Collapse
                                        in={openRows[row.id]}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Typography
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          align="left"
                                        >
                                          Registro de abonos
                                        </Typography>
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                Fecha de registro
                                              </TableCell>
                                              <TableCell>Vendedor</TableCell>
                                              <TableCell>
                                                Tipo de pago
                                              </TableCell>
                                              <TableCell>Abono</TableCell>
                                              <TableCell>Sucursal</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {filteredPayments.map((payment) => {
                                              payment.payment_name ===
                                              "Dinero en efectivo"
                                                ? (cash_total =
                                                    cash_total +
                                                    parseInt(payment.amount))
                                                : (transfer_total =
                                                    transfer_total +
                                                    parseInt(payment.amount));
                                              return (
                                                <TableRow key={payment.id}>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {formatDate(
                                                      format(
                                                        new Date(
                                                          payment.payment_date
                                                        ),
                                                        "yyyy-MM-dd HH:mm"
                                                      )
                                                    )}
                                                  </TableCell>
                                                  <TableCell>
                                                    {payment.user_name}
                                                  </TableCell>
                                                  <TableCell>
                                                    {payment.payment_name}
                                                    {payment.folio
                                                      ? ` con folio ${payment.folio}`
                                                      : null}
                                                  </TableCell>
                                                  <TableCell>
                                                    ${payment.amount}
                                                  </TableCell>
                                                  <TableCell>
                                                    {payment.office_name}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </Collapse>
                                    </CustomCell>
                                  </TableRow>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                          <TableRow>
                            <CustomCell
                              colSpan={5}
                              sx={{ paddingTop: "30px" }}
                            />
                            <CustomCell align="right">
                              <strong>Total efectivo</strong>
                            </CustomCell>
                            <CustomCell
                              sx={{ paddingLeft: "5px" }}
                            ></CustomCell>
                            <CustomCell
                              sx={{ paddingLeft: "5px" }}
                            ></CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              $ {cash_total}
                            </CustomCell>
                          </TableRow>
                          <TableRow>
                            <CustomCell
                              colSpan={5}
                              sx={{ paddingTop: "30px" }}
                            />
                            <CustomCell align="right">
                              <strong>Total transferencias</strong>
                            </CustomCell>
                            <CustomCell
                              sx={{ paddingLeft: "5px" }}
                            ></CustomCell>
                            <CustomCell
                              sx={{ paddingLeft: "5px" }}
                            ></CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              $ {transfer_total}
                            </CustomCell>
                          </TableRow>
                          <TableRow>
                            <CustomCell
                              colSpan={5}
                              sx={{ paddingTop: "30px" }}
                            />
                            <CustomCell align="right">
                              <strong>Total</strong>
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              $ {totalAmountPaid(office_data)}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              $ {totalAmountDebt(office_data)}
                            </CustomCell>
                            <CustomCell sx={{ paddingLeft: "5px" }}>
                              $ {totalAmount(office_data)}
                            </CustomCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </ScrollableContainer>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default MoneyReport;
