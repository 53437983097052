import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../interceptors/axiosInstance";
import "./login.css";
import Logo from "../assets/images/Logo.png";
import { SkeletonSideBarComponent } from "../components/skeletonComponents";
import { useMediaQuery } from "@mui/material";
import LoadingScreen from "../components/loadingScreen";

const Login = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");

  const logIn = (e) => {
    setIsLoading(true);
    e.preventDefault();
    axiosInstance
      .post(
        "/login/",
        {
          username: user,
          password: pass,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("is_superuser", response.data.is_superuser);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("user_id", response.data.user_id);
        navigate("/menu/home");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          text: "Usuario o contraseña incorrectos",
          icon: "error",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
        });
      });
  };

  return (
    <div className="loginContainer">
      {isLoading ? (
        isMobile ? (
          <LoadingScreen />
        ) : (
          <SkeletonSideBarComponent />
        )
      ) : (
        <>
          <img src={Logo} className="main_logo" alt="CompanyLogo"></img>
          <form onSubmit={logIn} className="loginFormContainer">
            <input
              className="inputBox"
              type="text"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              placeholder="Usuario"
            />
            <input
              className="inputBox"
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              placeholder="Contraseña"
            />
            <button className="loginButton">Iniciar sesión</button>
          </form>
        </>
      )}
    </div>
  );
};

export default Login;
