import React, { useState, useEffect } from "react";
import { CardSkeletonComponent } from "../components/skeletonComponents";
import { CardTemplate } from "../components/cardComponent";
import { GetAllBusses } from "../api/apiFunctions";
import { useNavigate } from "react-router-dom";
import { CustomIconButton } from "../components/buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axiosInstance from "../interceptors/axiosInstance";
import LoadingScreen from "../components/loadingScreen";
import TableComponent from "../components/tableComponent";
import { useMediaQuery } from "@mui/material";

function DeleteDeparture() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [busId, setBusId] = useState(null);
  const [isDeletingData, setIsDeletingData] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");

  const handleBusClick = (row) => {
    setBusId(row.id);
  };

  const deleteBusClick = () => {
    Swal.fire({
      title: "Atencion",
      text: "¿Seguro que desea borrar el autobus, se perdera la informacion del mismo?",
      showDenyButton: true,
      icon: "warning",
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "#0da290",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteDeparture();
      }
    });
  };

  const handleDeleteDeparture = () => {
    setIsDeletingData(true);
    axiosInstance
      .delete(`/bus/${busId}/`)
      .then((response) => {
        setIsDeletingData(false);
        Swal.fire({
          title: "Autobús eliminado",
          text: "El autobús se ha eliminado con éxito.",
          icon: "success",
          iconColor: "#1ccda0",
          confirmButtonColor: "#0da290",
          willClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        setIsDeletingData(false);
        if (error.code === "TOKEN_EXP") {
          Swal.fire({
            title: "Error",
            text: "Sesión cerrada por seguridad, inicie nuevamente",
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
          navigate("/");
          localStorage.clear();
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            iconColor: "#1ccda0",
            confirmButtonColor: "#0da290",
          });
        }
      });
  };

  useEffect(() => {
    GetAllBusses(
      "/bus/",
      (response) => {
        setData(response);
      },
      (loading) => {
        setIsLoading(loading);
      },
      (path) => {
        navigate(path);
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, [navigate]);

  return (
    <div className="editDepartureContainer">
      <div className="editDepartureAvailableDepartures">
        {isDeletingData && <LoadingScreen />}
        {isLoading ? (
          <CardSkeletonComponent nRows={2} />
        ) : data ? (
          isMobile ? (
            <CardTemplate dataInfo={data} clickHandle={handleBusClick} />
          ) : (
            <TableComponent
              dataInfo={data}
              headers={["Autobus", "Fecha", "Ruta", "Hora"]}
              cellName={["bus_name", "date", "route_name", "time"]}
              clickHandle={handleBusClick}
            />
          )
        ) : (
          <></>
        )}
      </div>
      <div>
        <CustomIconButton
          maxWidth={"230px"}
          maxHeight={"56px"}
          onClick={() => deleteBusClick()}
          text={"Borrar Autobús"}
          icon={<DeleteIcon />}
          disabled={busId === null ? true : false}
        />
      </div>
    </div>
  );
}

export default DeleteDeparture;
