import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://vds-app-vtreu.ondigitalocean.app/api",
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { access } = response.data;
    const { refresh } = response.data;
    if (access && refresh) {
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refresh_token", refresh);
    }
    return response;
  },
  (error) => {
    const err = error.response?.status ? error.response : null;

    if (err.status === 401) {
      if (err.data.code === "token_not_valid") {
        const customError = Error(error.response.data.detail, {
          cause: error,
        });
        customError.code = "TOKEN_EXP";
        return Promise.reject(customError);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
