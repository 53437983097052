import React from "react";
import "./home.css";

const Home = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="homeContainer">
      <h1>Bienvenido {localStorage.getItem("username")}</h1>
      <h3>Fecha: {currentDate}</h3>
    </div>
  );
};

export default Home;
